import axios from "axios";
import { APIURL } from "../config";
import useAuth from "../utils/useAuth";

const DashboardChangeSelectedFiltersAPI = async (data) => {
  const user = useAuth();
  const url = APIURL + "/document/viewdashboardstatusfiltered";
  return await axios.post(url, { access_token: user.token, ...data });
};

export default DashboardChangeSelectedFiltersAPI;
