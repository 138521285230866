import { List, ListItem } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export function Tip(props) {
  function matchData(data) {
    try {
      // console.log("SSSS",props)
      const questionData = props.highlightsData.filter(question => question.infoQuestion == data)[0].fields.filter(field => field.dataType == props.highlightsData[0].fields[0].dataType)
      return questionData[0]
    }
    catch (e) {
      console.log("Error in matchData")
      return {}
    }
  }
  return (
    <div style={{ background: 'white' }}>
      <List>
        {
          props.highlightsArr.map((item, index) => (
            <ListItem style={{ padding: '0px' }} key={index}>
              <ListItemButton style={{ padding: '2px 8px' }} onClick={() => props.onConfirm({ text: item.comment.text, emoji: "", index: index })}>
                <ListItemIcon style={{ minWidth: '32px' }}>
                  {
                    matchData(item.comment.text).dataUser && matchData(item.comment.text).dataUser.position ? <CheckCircleIcon style={{ color: 'green' }} /> : <CheckCircleIcon style={{ color: 'grey' }} />
                  }
                </ListItemIcon>
                <ListItemText primary={item.comment.text} />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>

    </div>
  )
}

export default Tip

// props.onConfirm({ text:'new new', emoji:"" })