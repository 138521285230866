import styled from "@emotion/styled";
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import Navigation from "../../sharedComponents/Navigation/Navigation";
import Sidebar from "../../sharedComponents/Sidebar/Sidebar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import GetAllPolicies from "../../api/GetAllPolicies";
import { useNavigate } from "react-router-dom";
import GetAllSchema from "../../api/GetAllSchema";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LaunchIcon from "@mui/icons-material/Launch";
import TrainingDialog from "./TrainingDialog";


const Root = styled("div")(({ theme }) => ({}));
const Container = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "240px auto",
}));

// const rows = [
//   {
//     id: "1",
//     name: "Test Form Policy",
//     type: "Document Processing",
//     lastTrained: "1 wk ago",
//     creator: "Aakash Varma",
//     status: "Published",
//   },
//   {
//     id: "2",
//     name: "Document Processing 29/06/2022, 08:28:24",
//     type: "Document Processing",
//     lastTrained: "2 wk ago",
//     creator: "Aakash Varma",
//     status: "Draft",
//   },
// ];

function SchemaDashboard() {
  // const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rows, setRows] = React.useState([])
  const [paginationSkip, setPaginationSkip] = React.useState(0)
  const [paginationTake, setPaginationTake] = React.useState(2)
  const navigate = useNavigate();
  const [selectedTrainingModel, setSelectedTrainingModel] = React.useState({})
  const [showTrainingDialog, setShowTrainingDialog] = React.useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    GetAllSchema({
      "skip": paginationSkip,
      "take": paginationTake
    })
      .then((response) => {
        console.log(response.data);
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [paginationSkip, paginationTake]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.value}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Name
          </span>
        );
      },
    },
    // toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    { field: "modelType", headerName: "Model Type", flex: 1 },
    {
      field: "lastTrained",
      headerName: "Last Trained",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {new Date(params.value).toLocaleString()}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Last Trained
          </span>
        );
      },
    },
    { field: "owner", headerName: "Creator", flex: 1 },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 112,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              onClick={() =>
                navigate(
                  "/schema_builder?id=" + params.row.schemaDefinitionId,
                  {
                    replace: true,
                  }
                )
              }
            >
              <CreateOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={e => {
                handleClick(e);
                setSelectedTrainingModel(params.row)
              }}
            >
              <MoreVertIcon color="primary" />
            </IconButton>
          </span>
        );
      },
    },
  ];

  return (
    <Root>

      {
        showTrainingDialog && <TrainingDialog open={showTrainingDialog} setOpen={setShowTrainingDialog} selectedTrainingModel={selectedTrainingModel} />
      }
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setShowTrainingDialog(true)
          }}
        >
          <ListItemIcon style={{ minWidth: "28px" }}>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Start Training</ListItemText>
        </MenuItem>
      </Menu>
      <Navigation />
      <Container>
        <div style={{ minHeight: "calc(100vh-52px)" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <Typography variant="h5" component="div">
              Available Models
            </Typography>
            <Button
              onClick={() => navigate("/schema_builder", { replace: true })}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                "&:hover": { borderWidth: "2px" },
              }}
            >
              Add
            </Button>
          </div>

          <DataGrid
            sx={{ border: "none" }}
            rows={rows}
            columns={columns}
            disableColumnMenu
            getRowId={(row) => row.schemaDefinitionId}
            // hideFooterPagination={true}
            autoHeight
            loading={isLoading}
            disableSelectionOnClick
            onPageSizeChange={e => {
              setPaginationTake(e)
            }}
            pageSize={paginationTake}
            rowsPerPageOptions={[2, 5, 10]}
            onPageChange={e => {
              console.log(e)
            }}
          />
        </div>
      </Container>
    </Root>
  );
}

export default SchemaDashboard;
