import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";

function AddSpecificInfoDialog(props) {
  const [name, setName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  return (
    <Dialog open={props.openDiag} onClose={() => props.setOpenDiag(false)}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.addSpecificInfoContainer(name);
          props.setOpenDiag(false);
          setName("");
        }}
      >
        <DialogTitle>Add Information</DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: "0px 24px 24px 24px" }}>
          <div style={{ width: "360px" }}>
            <TextField
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoFocus
              margin="dense"
              label="Name"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              fullWidth
              variant="standard"
            />
          </div>
          {error && (
            <Alert
              severity="error"
              style={{ marginTop: "16px" }}
              sx={{ width: "360px" }}
            >
              {error}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => props.setOpenDiag(false)} color="inverse">
            Cancel
          </Button>
          <Button type="submit">
            {" "}
            {isLoading ? <CircularProgress color="inherit" size={23} /> : "Add"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddSpecificInfoDialog;
