import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Checkbox, CircularProgress, colors, Divider, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { PropaneSharp } from '@mui/icons-material';
import CreateUserAPI from '../../../api/CreateUser';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Colors } from '../../theme'
import EditRoleAPI from '../../../api/EditRole';
import CreateRoleAPI from '../../../api/CreateRole';


function AddRoleDialog(props) {

    const [isLoading, setIsLoading] = React.useState(false)
    const [rolename, setRolename] = React.useState("")
    const [rows, setRows] = React.useState([])
    const [error, setError] = React.useState('')

    React.useEffect(() => {
        console.log("props updated", props)
        setRows(props.permissionTemplate)
    }, [props])

    function changePermission(checked, index, permission) {
        let newRows = rows;
        switch (permission) {
            case "isEdit":
                newRows[index].isEdit = checked
                setRows([...newRows])
                break;
            case "isCreate":
                newRows[index].isCreate = checked
                setRows([...newRows])
                break;

            case "isDelete":
                newRows[index].isDelete = checked
                setRows([...newRows])
                break;

            case "isView":
                newRows[index].isView = checked
                setRows([...newRows])
                break;

            default:
                return;
        }
    }

    function AddRole() {
        setError('')
        if (rolename == "") {
            setError("Please enter a name for the role.")
        } else {
            setIsLoading(true)
            CreateRoleAPI({
                rolename: rolename,
                permissionlist: rows
            }).then(response => {
                setIsLoading(false)
                if (response.data.successResponse) {
                    console.log(response.data)
                    window.location.reload()
                }
                else {
                    setError(response.data.error)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (
        <Dialog open={props.open} onClose={() => props.setOpen(false)}>

            <DialogTitle>Add Role</DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: '0px 24px 24px 24px' }}>
                <div style={{ width: '480px' }}>
                    <TextField
                        margin="dense"
                        value={rolename || ""}
                        onChange={e => setRolename(e.target.value)}
                        label="Name"
                        inputProps={{
                            style: {
                                fontSize: '14px'
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                fontSize: '14px'
                            }
                        }}
                        fullWidth
                        variant="standard"
                    />

                    <Table aria-label="simple table" size='small' style={{ marginTop: '24px' }}>
                        <TableHead>
                            <TableRow style={{ background: Colors.body_bg, }}>
                                <TableCell align='left'>Tenant Permissions</TableCell>
                                <TableCell align="center">Edit</TableCell>
                                <TableCell align="center">Create</TableCell>
                                <TableCell align="center">Delete</TableCell>
                                <TableCell align="right">View</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center"><Checkbox checked={row.isEdit || false} size="small" style={{ padding: '4px' }} onChange={(e) => changePermission(e.target.checked, index, "isEdit")} /></TableCell>
                                    <TableCell align="center"><Checkbox checked={row.isCreate || false} size="small" style={{ padding: '4px' }} onChange={(e) => changePermission(e.target.checked, index, "isCreate")} /></TableCell>
                                    <TableCell align="center"><Checkbox checked={row.isDelete || false} size="small" style={{ padding: '4px' }} onChange={(e) => changePermission(e.target.checked, index, "isDelete")} /></TableCell>
                                    <TableCell align="right"><Checkbox checked={row.isView || false} size="small" style={{ padding: '4px' }} onChange={(e) => changePermission(e.target.checked, index, "isView")} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>

                {
                    error && <Alert severity="error" style={{ marginTop: '16px' }} sx={{ width: '480px' }}>{error}</Alert>

                }

            </DialogContent>

            <Divider />
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="inverse">Cancel</Button>
                <Button type="submit" onClick={e => AddRole()}>  {
                    isLoading ? <CircularProgress color="inherit" size={23} /> : "Add"
                }</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddRoleDialog