import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "./useAuth";

function RequireAuth() {
    let user = useAuth();
    let location = useLocation();

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    if (user.token) {
        const decodedJwt = parseJwt(user.token);
        if (decodedJwt.exp * 1000 < Date.now()) {
            user.logout();
        }
    }

    if (!user.token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{ from: location }} />;
    }

    return <Outlet />;
}

export default RequireAuth