import React from 'react'
import { ResizableBox } from 'react-resizable';
import './styles.css'



function TestPage() {

    return (
        <ResizableBox width={200} height={200} style={{ border: '1px solid black' }}
            minConstraints={[100, 100]} maxConstraints={[300, 300]} resizeHandles={['e']}>
            <span>Contents</span>
        </ResizableBox>
    )
}

export default TestPage