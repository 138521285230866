import React from "react";

function useAuth() {
  const user = {
    username: localStorage.getItem("username"),
    user_email: localStorage.getItem("email"),
    token: localStorage.getItem("token"),
    role: localStorage.getItem("role"),
    permissions: JSON.parse(localStorage.getItem("Permissions")),

    logout: () => {
      console.log("logout called");
      localStorage.removeItem("username");
      localStorage.removeItem("user_email");
      localStorage.removeItem("token");
      localStorage.removeItem("FirstName");
      localStorage.removeItem("LastName");
      localStorage.removeItem("Permissions");
      window.location.href = "/";
    },
  };
  return user;
}

export default useAuth;
