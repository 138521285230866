import axios from "axios";
import { APIURL } from "../config";
import useAuth from "../utils/useAuth";

const RestartMLServerAPI = async () => {
  const user = useAuth();
  const url = APIURL + "/schema/edittransactionmlrestart";
  return await axios.post(url, { access_token: user.token });
};

export default RestartMLServerAPI;
