import axios from "axios";
import { APIURL } from "../config";
import useAuth from "../utils/useAuth";

const GetTransactionsListAPI = async (data) => {
  const user = useAuth();
  const url = APIURL + "/schema/viewtransactionlist";
  return await axios.post(url, { access_token: user.token, ...data });
};

export default GetTransactionsListAPI;
