import React from "react";
import { useEffect } from "react";
import useAuth from "../../utils/useAuth";

function ForceLogout() {
  const user = useAuth();
  useEffect(() => {
    user.logout();
  }, []);
  return <div></div>;
}

export default ForceLogout;
