import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ReactJson from "react-json-view";
import CreateTransactionAPI from "../../api/createTransaction";
import AddDocumentTransactionAPI from "../../api/AddDocumentTransaction";
import AddTransactionItemAPI from "../../api/AddTransactionItem";
import useAuth from "../../utils/useAuth";
import { Colors } from "../theme";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuidv4 } from 'uuid';

function AddTransaction(props) {
  const { onClose } = props;
  const user = useAuth();
  const today = (parseInt(new Date().getMonth()) + 1) + "/" + new Date().getDate() + "/" + new Date().getFullYear();
  const today1year = (parseInt(new Date().getMonth()) + 1) + "/" + new Date().getDate() + "/" + (parseInt(new Date().getFullYear()) + 1);
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [createTxn, setCreateTxn] = useState({
    "Record Id": uuidv4(),
    Reference: uuidv4(),
    "Items Available": "common",
    "Start Date": today,
    "End Date": today1year,
  });
  const [txnID, setTxnID] = useState("");

  const [addTransactionItems, setAddTransactionItems] = useState({
    schemaId: "3083ebdc-40eb-4625-847f-11fa20140820",
    subTypes: "common",
    dataSources: "Invoice",
    transactionId: txnID,
    transactionDocuments: [
      {
        docId: "",
        dataType: "Invoice",
      },
    ],
    transactionItemDetails: {},
  });
  const [selectedDoc, setSelectedDoc] = useState("");
  const [response1, setResponse1] = useState(null);
  const [response2, setResponse2] = useState(null);
  const [response3, setResponse3] = useState(null);

  const onEditADDDeleteCreateTransaction = (e) => setCreateTxn(e.updated_src);

  const onEditADDDeleteTransactionItems = (e) =>
    setAddTransactionItems(e.updated_src);

  /** CREATE TRANSACTION SUBMIT HANDLER */
  const handleCreateTransaction = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const { data } = await CreateTransactionAPI(createTxn);
      setTxnID(data.transactionId);
      setAddTransactionItems(addTransactionItems => ({
        ...addTransactionItems,
        transactionId: data.transactionId
      }));
      setResponse1(data);
      setIsLoading(false);
    } catch (e) {
      setResponse1(e);
      setIsLoading(false);
    }
  };

  /** ADD TRANSACTION ITEM SUBMIT HANDLER */
  const handleAddTransactionItems = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const { data } = await AddTransactionItemAPI({
        ...addTransactionItems,
        // "access_token": user.token,
      });
      console.log("RECEIVED TRANSACTION ITEM RESPONSE :", data);

      setCurrentStep(1);
      setResponse3(data);
      setIsLoading(false);
    } catch (e) {
      setResponse3(e);
      setIsLoading(false);
    }
  };

  /* ONCHANGE : ADD NEW Document*/
  const handleAttachDoc = (e) => {
    e.preventDefault();
    setSelectedDoc(e.target.files[0]);
    setCurrentStep(2);
  };

  /** SUBMIT Document HANDLER */
  const handleSubmitDocument = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = new FormData();
    formData.append("transactionDataId", txnID);
    formData.append("access_token", user.token);
    formData.append("files", selectedDoc);

    try {
      const { data } = await AddDocumentTransactionAPI(formData);
      console.log("RECEIVED AddDocumentTransactionAPI Res:", data);
      setAddTransactionItems(addTransactionItems => ({
        ...addTransactionItems,
        transactionDocuments: [
          {
            docId: data[0].transactionDocumentId,
            dataType: 'Invoice'
          }
        ]
      }));

      console.log(data)
      setCurrentStep(3);
      setResponse2(data);
      setIsLoading(false);
    } catch (e) {
      setResponse2(e);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: "999999" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={true} onClose={onClose} maxWidth="lg">
        <DialogTitle>Add Transaction</DialogTitle>
        <DialogContent>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" component="div" gutterBottom>
                Create Transaction
              </Typography>
              <Box
                style={{
                  minWidth: "500px",
                  border: "2px dashed " + Colors.border,
                  padding: "16px 24px",
                }}
              >
                <ReactJson
                  style={{ fontFamily: "inherit" }}
                  name="createTransaction"
                  src={createTxn}
                  onEdit={onEditADDDeleteCreateTransaction}
                  onDelete={onEditADDDeleteCreateTransaction}
                  onAdd={onEditADDDeleteCreateTransaction}
                  enableClipboard={false}
                />
                <Button
                  onClick={handleCreateTransaction}
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "16px" }}
                  disabled={currentStep !== 1}
                >
                  Submit
                </Button>
                {response1 && (
                  <ReactJson
                    style={{ fontFamily: "inherit", marginTop: "16px" }}
                    name="createTransactionResponse"
                    src={response1}
                    enableClipboard={false}
                    displayDataTypes={false}
                    collapsed={true}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                style={{ marginBottom: "8px" }}
              >
                Add Document
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                style={{ marginBottom: "8px" }}
              >
                <Button variant="outlined" component="label">
                  Choose File
                  <input
                    hidden
                    multiple
                    type="file"
                    onChange={(e) => handleAttachDoc(e)}
                  />
                </Button>

                {selectedDoc && (
                  <Typography
                    variant="subtitle2"
                    component="span"
                    color="primary"
                    style={{ marginLeft: "8px" }}
                  >
                    {selectedDoc.name}
                  </Typography>
                )}
              </Stack>
              <TextField
                margin="dense"
                id="transactionDataID"
                label="Transaction Data ID"
                type="text"
                fullWidth
                size="small"
                value={txnID}
              />
              <Button
                variant="outlined"
                color="primary"
                style={{ marginTop: "16px" }}
                onClick={handleSubmitDocument}
                disabled={currentStep !== 2}
              >
                Submit
              </Button>
              {response2 && (
                <ReactJson
                  style={{ fontFamily: "inherit", marginTop: "16px" }}
                  name="addTransactionDocumentResponse"
                  src={response2}
                  enableClipboard={false}
                  displayDataTypes={false}
                  collapsed={false}
                />
              )}
            </Grid>
            <Divider />
            <Grid item xs={12}>
              <Typography variant="h6" component="div" gutterBottom>
                Add Transaction Items
              </Typography>
              <Box
                style={{
                  minWidth: "500px",
                  border: "2px dashed " + Colors.border,
                  padding: "16px 24px",
                }}
              >
                <ReactJson
                  style={{ fontFamily: "inherit" }}
                  name="addTransactionItems"
                  src={addTransactionItems}
                  onEdit={onEditADDDeleteTransactionItems}
                  onDelete={onEditADDDeleteTransactionItems}
                  onAdd={onEditADDDeleteTransactionItems}
                  enableClipboard={true}

                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddTransactionItems}
                  style={{ marginTop: "8px" }}
                  disabled={currentStep !== 3}
                >
                  Submit
                </Button>
              </Box>
              {response3 && (
                <ReactJson
                  style={{ fontFamily: "inherit", marginTop: "16px" }}
                  name="addTransactionItemsResponse"
                  src={response3}
                  enableClipboard={false}
                  displayDataTypes={false}
                  collapsed={false}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddTransaction;
