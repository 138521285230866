import styled from "@emotion/styled";
import { Button, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import Navigation from "../../../sharedComponents/Navigation/Navigation";
import Sidebar from "../../../sharedComponents/Sidebar/Sidebar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GetAllRoles from "../../../api/GetAllRoles";
import EditRoleDialog from "./EditRoleDialog";
import DeleteRoleDialog from "./DeleteRoleDialog";
import AddRoleDialog from "./AddRoleDialog";

const Root = styled("div")(({ theme }) => ({}));
const Container = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "240px auto",
}));

function ManageRoles() {
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  // const [openAddRoleDiag, setOpenAddRoleDiag] = React.useState(true)
  const [openEditRoleDiag, setOpenEditRoleDiag] = React.useState(false);
  const [openDeleteRoleDiag, setOpenDeleteRoleDiag] = React.useState(false);
  const [openAddRoleDiag, setOpenAddRoleDiag] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState({});
  const [permissionTemplate, setPermissionTemplate] = React.useState([]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.value}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Name
          </span>
        );
      },
    },
    { field: "tenant", headerName: "Tenant", width: 260 },
    {
      field: "action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              onClick={() => {
                setSelectedRole(params.row);
                setOpenEditRoleDiag(true);
              }}
            >
              <CreateOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              onClick={() => {
                setSelectedRole(params.row);
                setOpenDeleteRoleDiag(true);
              }}
            >
              <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
          </span>
        );
      },
    },
  ];

  React.useEffect(() => {
    GetAllRoles({
      skip: "0",
      take: "10",
      filtertext: "",
    })
      .then((response) => {
        if (response.data.error) {
        } else {
          setRows(response.data.data);
          let permissionTeplateSchema = [];
          response.data.subData.forEach((item) => {
            permissionTeplateSchema.push({
              type: "Permissions",
              isEdit: false,
              isCreate: false,
              isDelete: false,
              isView: false,
              name: item,
            });
          });
          setPermissionTemplate(permissionTeplateSchema);
          console.log(response.data.subData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Root>
      <Navigation />
      <EditRoleDialog
        open={openEditRoleDiag}
        setOpen={setOpenEditRoleDiag}
        role={selectedRole}
      />
      <DeleteRoleDialog
        open={openDeleteRoleDiag}
        setOpen={setOpenDeleteRoleDiag}
        role={selectedRole}
      />
      <AddRoleDialog
        open={openAddRoleDiag}
        setOpen={setOpenAddRoleDiag}
        permissionTemplate={permissionTemplate}
      />
      <Container>
        <div style={{ minHeight: "calc(100vh-52px)" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <Typography variant="h5" component="div">
              Manage Roles
            </Typography>
            <Button
              variant="outlined"
              onClick={(e) => {
                setOpenAddRoleDiag(true);
              }}
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                "&:hover": { borderWidth: "2px" },
              }}
            >
              Add
            </Button>
          </div>

          <DataGrid
            sx={{ border: "none" }}
            rows={rows}
            columns={columns}
            disableColumnMenu
            isLoading={isLoading}
            getRowId={(row) => row.id}
            // hideFooterPagination={true}
            autoHeight
            disableSelectionOnClick
            loading={isLoading}
          />
        </div>
      </Container>
    </Root>
  );
}

export default ManageRoles;
