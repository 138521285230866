import axios from "axios";
import { APIURL } from "../config";
import useAuth from "../utils/useAuth";


const GetAllSchema = async (data) => {
    const user = useAuth()
    const url = APIURL + "/schema/viewschemadashboard"
    return await axios.post(url, { access_token: user.token, ...data })
}

export default GetAllSchema