import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styled from "@emotion/styled";
import { Colors } from "../theme";
import ReactJson from "react-json-view";

const EditableCell = styled("input")(({ theme }) => ({
  width: "calc(100% - 4px)",
  marginLeft: "2px",
  height: "33px",
  border: "none",
  padding: "0px 14px",
  "&:focus": {
    outline: "2px solid " + Colors.info,
  },
}));

function AddSpecificFieldDialog(props) {
  console.log(props);
  const [name, setName] = React.useState("");
  const [type, setType] = React.useState("Text");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [schemaData, setSchemaData] = React.useState({});

  return (
    <Dialog
      open={props.openDiag}
      onClose={() => {
        props.setOpenDiag(false);
        setName("");
        setSchemaData({});
        setType("Text");
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log(schemaData);
          props.addInformation(name, [schemaData]);
          props.setOpenDiag(false);
          setName("");
          setSchemaData({});
          setType("Text");
        }}
      >
        <DialogTitle>Add Information</DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: "0px 24px 24px 24px" }}>
          <div style={{ width: "520px" }}>
            <TextField
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoFocus
              margin="dense"
              label="Name"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              margin="dense"
              label="Type"
              select
              value={type}
              onChange={(e) => setType(e.target.value)}
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              SelectProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              fullWidth
              variant="standard"
            >
              <MenuItem value={"Text"} style={{ fontSize: "14px" }}>
                Text
              </MenuItem>
              <MenuItem value={"Complex"} style={{ fontSize: "14px" }}>
                Complex
              </MenuItem>
            </TextField>

            {type == "Complex" && (
              <div
                style={{
                  //  display: 'flex',
                  //   flexDirection: 'column',
                  //    alignItems: 'flex-end',
                  marginTop: "16px",
                }}
              >
                <ReactJson
                  style={{ fontFamily: "inherit" }}
                  name="schemaData"
                  src={schemaData}
                  onEdit={(e) => setSchemaData(e.updated_src)}
                  onDelete={(e) => setSchemaData(e.updated_src)}
                  onAdd={(e) => setSchemaData(e.updated_src)}
                  defaultValue={""}
                  enableClipboard={false}
                />

                {/* <Button variant="outlined" style={{ borderWidth: '2px', borderRadius: '0px', padding: '2px 4px' }} onClick={e => {
                                    setSchemaData(schemaData => [
                                        ...schemaData,
                                        {
                                            name: 'New Field',
                                            type: 'Text'
                                        }
                                    ])
                                }}>Add +</Button>
                                <TableContainer style={{ marginTop: '8px', border: '2px solid #cccccc' }}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell align="right">Type</TableCell>
                                                <TableCell align="right">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {schemaData.map((row, rowIndex) => (
                                                <TableRow

                                                    key={rowIndex}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" style={{ padding: "0px" }}>
                                                        <EditableCell onChange={e => {
                                                            // setSchemaData(oldSchemaData => )
                                                            setSchemaData(data => ([
                                                                ...data.slice(0, rowIndex), { ...data[rowIndex], name: e.target.value }, ...data.slice(rowIndex + 1)
                                                            ]))
                                                        }} value={row.name}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right">{row.type}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                setSchemaData(schemaData.filter((schemaItem, schemaItemIndex) => schemaItemIndex !== rowIndex))
                                                            }}
                                                            color="primary"
                                                            component="span"
                                                            sx={{
                                                                width: "24px",
                                                                height: "24px",
                                                                marginLeft: "4px",
                                                            }}

                                                        >
                                                            <DeleteOutlineOutlinedIcon color="primary" />
                                                        </IconButton >
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer> */}
              </div>
            )}
          </div>
          {error && (
            <Alert
              severity="error"
              style={{ marginTop: "16px" }}
              sx={{ width: "360px" }}
            >
              {error}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpenDiag(false);
              setName("");
              setSchemaData({});
              setType("Text");
            }}
            color="inverse"
          >
            Cancel
          </Button>
          <Button type="submit">
            {" "}
            {isLoading ? <CircularProgress color="inherit" size={23} /> : "Add"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddSpecificFieldDialog;
