import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ManageRoles from "./pages/AdminPanel/ManageRoles/ManageRoles";
import ManageUsers from "./pages/AdminPanel/ManageUsers/ManageUsers";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForceLogout from "./pages/ForceLogout/ForceLogout";
import EnterNewPasswordPage from "./pages/ForgotPassword/EnterNewPassword";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPassword";
import LoginPage from "./pages/Login/LoginPage";
import Profile from "./pages/Profile/Profile";
import SchemaBuilder from "./pages/SchemaBuilder/SchemaBuilder";
import SchemaDashboard from "./pages/SchemaDashboard/SchemaDashboard";
// import NewHighlighter from "./pages/TestHighlight/NewHighlighter";
// import TestHighlight from "./pages/TestHighlight/TestHighlight";
import TestPage from "./pages/TestPage/TestPage";
import { theme } from "./pages/theme";
// import ValidateTransaction from "./pages/ValidateTransaction/ValidateTransaction";
import RequireAuth from "./utils/RequireAuth";
import React from "react";
// import ViewTransaction from "./pages/ViewTransaction/ViewTransaction";

const ViewTransaction = React.lazy(() => import("./pages/ViewTransaction/ViewTransaction"));
const ViewTransactionAzure = React.lazy(() => import("./pages/ViewTransactionAzure/ViewTransactionAzure"));
// import ValidateTransactionAzure from "./pages/ValidateTransactionAzure/ValidateTransaction";
const ValidateTransactionAzure = React.lazy(() => import('./pages/ValidateTransactionAzure/ValidateTransaction'));
const ValidateTransaction = React.lazy(() => import('./pages/ValidateTransaction/ValidateTransaction'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/new-password" element={<EnterNewPasswordPage />} />
          <Route path="/test-page" element={<TestPage />} />
          <Route path="/force-logout" element={<ForceLogout />} />
          <Route element={<RequireAuth />}>
            <Route path="/admin_panel/manage_users" element={<ManageUsers />} />
            <Route path="/admin_panel/manage_roles" element={<ManageRoles />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/schema_dashboard" element={<SchemaDashboard />} />
            <Route path="/schema_builder" element={<SchemaBuilder />} />
            <Route path="/edit_profile/" element={<Profile />} />
            {/* <Route path="/test_highlighter/" element={<TestHighlight />} />
            <Route path="/test_highlighter_new/" element={<NewHighlighter />} /> */}
            <Route
              path="/validate_transaction/"
              element={<React.Suspense fallback={<>...</>}><ValidateTransaction /></React.Suspense>}
            />
            <Route
              path="/validate_transaction2/"
              element={<React.Suspense fallback={<>...</>}><ValidateTransactionAzure /></React.Suspense>}
            />
            {/* <Route path="/view_transaction/" element={<ViewTransaction />} /> */}
            {/* <Route path="/view_transaction2/" element={<ViewTransactionAzure />} /> */}

            <Route
              path="/view_transaction/"
              element={<React.Suspense fallback={<>...</>}><ViewTransaction /></React.Suspense>}
            />
            <Route
              path="/view_transaction2/"
              element={<React.Suspense fallback={<>...</>}><ViewTransactionAzure /></React.Suspense>}
            />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />{" "}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
