import { createTheme } from "@mui/material";

export const Colors = {
    primary: "#1172FF",
    secondary: "#95defb",
    success: "#4CAF50",
    info: "#00a2ff",
    danger: "#FF5722",
    warning: "#ED6C02",
    dark: "#0e1b20",
    light: "#aaa",
    muted: "#abafb3",
    border: "#DDDFE1",
    inverse: "#2F3D4A",
    shaft: "#333",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",

    //Solid Colors
    white: "#fff",
    black: "#000",
};
const defaultTheme = createTheme();
export const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary
        },
        secondary: {
            main: Colors.secondary
        },
        black: {
            main: Colors.black,
            contrastText: Colors.white
        },
        white: defaultTheme.palette.augmentColor({
            color: {
                main: Colors.white
            },
        }),
        warning: {
            main: Colors.warning
        },
        inverse: defaultTheme.palette.augmentColor({
            color: {
                main: Colors.inverse
            },
        }),
    },
    typography: {
        subtitle1: {
            fontSize: '14px'
        },
        button: {
            textTransform: 'unset',
            fontWeight: 600
        }
    },

});