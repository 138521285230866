import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import BackupSharpIcon from "@mui/icons-material/BackupSharp";
import { IconButton, Typography } from "@mui/material";
import { Colors } from "../theme";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";
import convertToSuitableUnit from "../../utils/convertToSuitableUnit";

const Input = styled("input")({
  display: "none",
});

export default function AddDocumentsDrawer(props) {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 285,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/assets/vectors/pdf.svg"
              style={{ width: "16px", marginRight: "8px" }}
            />
            {params.row.name}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Name
          </span>
        );
      },
    },
    // { field: "createdDate", headerName: "Created date", width: 110 },
    {
      field: "size",
      headerName: "Size",
      width: 100,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {convertToSuitableUnit(params.row.size)}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            color="primary"
            component="span"
            sx={{ width: "36px", height: "36px" }}
            onClick={(e) => {}}
          >
            <DeleteOutlineOutlinedIcon color="primary" />
          </IconButton>
        );
      },
      align: "right",
      headerAlign: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Action
          </div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      name: "ABCdfwererer.pdf",
      createdDate: "7 Mar 2022",
      size: "74Kb",
    },
  ];
  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
    >
      {props.collections[props.collectionIndex] &&
      props.collections[props.collectionIndex].documents.length < 1 ? (
        <div
          style={{
            display: "flex",
            width: "480px",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography variant="h6" style={{ padding: "16px" }}>
            {props.collections.length > 0 &&
              props.collections[props.collectionIndex].name}
          </Typography>
          <Divider />
          <div
            style={{
              padding: "16px",
              display: "grid",
              placeItems: "center",
              flex: 1,
            }}
          >
            <div style={{ display: "grid", placeItems: "center" }}>
              <div
                style={{
                  background: Colors.primary,
                  height: "82px",
                  width: "82px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                }}
              >
                <BackupSharpIcon
                  style={{ fontSize: "52px", color: "#ffffff" }}
                />
              </div>
              <Typography
                variant="body2"
                style={{ fontWeight: 600, margin: "32px 0px 16px 0px" }}
              >
                Add 5 or more documents with the same layout
              </Typography>
              <label htmlFor="upload-documents">
                <Input
                  accept="application/pdf"
                  id="upload-documents"
                  multiple
                  type="file"
                  onChange={(e) => {
                    props.addDocsToCollection(e.target.files);
                    // getBase64(e.target.files[0], (result) => {
                    //     setProfilePic(result)
                    // })
                  }}
                />
                <Button
                  variant="contained"
                  disableElevation
                  component="span"
                  color="primary"
                >
                  Add documents
                </Button>
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "480px",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography variant="h6" style={{ padding: "16px 16px 0px 16px" }}>
            {props.collections.length > 0 &&
              props.collections[props.collectionIndex].name}
          </Typography>
          <Typography variant="body2" style={{ padding: "0px 16px 16px 16px" }}>
            3 documents
          </Typography>
          <Divider />
          <div>
            <label htmlFor="upload-more-documents">
              <Input
                accept="application/pdf"
                id="upload-more-documents"
                multiple
                type="file"
                onChange={(e) => {
                  props.addDocsToCollection(e.target.files);
                }}
              />
              <Button
                startIcon={<AddIcon />}
                component="span"
                sx={{
                  borderRadius: "0px",
                  padding: "8px 16px",
                }}
              >
                Add More
              </Button>
            </label>
          </div>
          <Divider />
          <div
            style={{
              padding: "16px",
              flex: 1,
              width: "100%",
            }}
          >
            <DataGrid
              sx={{ border: "none" }}
              rows={
                props.collections[props.collectionIndex] &&
                props.collections[props.collectionIndex].documents
              }
              disableSelectionOnClick
              columns={columns}
              getRowId={(row) => row.name}
              disableColumnMenu
              hideFooterPagination={true}
              autoHeight
            />
          </div>
        </div>
      )}
    </Drawer>
  );
}
