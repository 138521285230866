import styled from "@emotion/styled";
import React from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Colors } from "../theme";
import { StepConnector } from "@mui/material";


const Root = styled('div')(({ theme }) => ({
    boxShadow: "0px 0px 6px -2px #f0f0f0",
    borderRight: '1px solid #f0f0f0',
    minHeight: 'calc(100vh - 52px)',
    padding: '16px'

}))

function LeftPanel(props) {


    return (
        <Root>
            <Stepper activeStep={props.activeTab} orientation="vertical">

                <Step expanded={true}>
                    <StepLabel
                    >
                        {props.activeTab == 0 ? <strong>Choose information to extract</strong> : <span>Choose information to extract</span>}
                    </StepLabel>

                    <StepContent sx={{ marginTop: '-6px' }}>

                        <span style={{ color: Colors.primary, fontSize: '0.9rem', }} >{props.highlights.length + " field(s)"}</span>
                    </StepContent>
                </Step>

                {/* <Step expanded={true}>
                        <StepLabel
                        >
                            {props.activeTab == 1 ? <strong>Add collections of documents</strong> : <span>Add collections of documents</span>}
                        </StepLabel>

                        <StepContent sx={{ marginTop: '-6px' }}>

                                    <span style={{ color: Colors.primary, fontSize: '0.9rem', }} >{props.collections.length+" collection(s)"}</span>
                        </StepContent>
                    </Step>

                    <Step expanded={true}>
                        <StepLabel
                        >
                            {props.activeTab == 2 ? <strong>Tag documents</strong> : <span>Tag documents</span>}
                        </StepLabel>

               
                    </Step>

                    <Step expanded={true}>
                        <StepLabel
                        >
                            {props.activeTab == 3 ? <strong>Model summary</strong> : <span>Model summary</span>}
                        </StepLabel>

              
                    </Step> */}



            </Stepper>
            {props.activeTab === 3 && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                        Reset
                    </Button> */}
                </Paper>
            )}
        </Root>
    )
}

export default LeftPanel