import React, { Component } from "react";
import "../style/Highlight.css";
export class Highlight extends Component {
  render() {
    const { position, onClick, onMouseOver, onMouseOut, comment, isScrolledTo, } = this.props;
    const { rects, boundingRect } = position;
    return (<div className={`Highlight ${isScrolledTo ? "Highlight--scrolledTo" : ""}`}>
      {comment ? (<div className="Highlight__emoji" style={{
        left: 20,
        top: boundingRect.top,
      }}>
        {comment.emoji}
      </div>) : null}
      <div className="Highlight__parts">
        {rects.map((rect, index) => (<div
          onMouseOver={onMouseOver} onMouseOut={onMouseOut}
          // onClick={onClick} 
          key={index} style={rect} className={`Highlight__part`} />))}
      </div>
    </div>);
  }
}
export default Highlight;