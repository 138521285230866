import styled from "@emotion/styled";
import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from "@mui/material";
import { bgcolor, borderRight } from "@mui/system";
import React from "react";
import GetSchemaData from "../../api/GetSchemaData";
import Navigation from "../../sharedComponents/Navigation/Navigation";
import LeftPanel from "./LeftPanel";
import RightSection from "./RightSection";
import qs from "query-string";
import EditSchemaAPI from "../../api/EditSchema";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Container = styled("div")({
  display: "grid",
  gridTemplateColumns: "280px auto",
});

function SchemaBuilder() {
  const schemaDefinitionId = qs.parse(window.location.search).id;

  const [activeTab, setActiveTab] = React.useState(0);
  const [highlights, setHighlights] = React.useState([]);
  const [specificHighlights, setSpecificHighlights] = React.useState([]);
  const [collections, setCollections] = React.useState([]);
  const [schemaDefinition, setSchemaDefinition] = React.useState({});
  const [tempState, setTempState] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    GetSchemaData({
      schemaDefinitionId: schemaDefinitionId,
    })
      .then((response) => {
        console.log(response.data);
        setHighlights(response.data.fields.common);
        setSpecificHighlights(response.data.fields.specific);
        setTempState(response.data.fields.specific);
        setSchemaDefinition(response.data.schemaDefinition);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function saveDataToAPI() {
    setIsLoading(true);
    EditSchemaAPI({
      schemaDefinition: {
        schemaDefinitionId: schemaDefinitionId,
        name: schemaDefinition.name,
        dataSources: schemaDefinition.dataSources,
        subTypes: schemaDefinition.subTypes || null,
      },
      fields: {
        common: highlights,
        specific: specificHighlights,
      },
    })
      .then((response) => {
        if (response.data.successResponse) {
          console.log(response.data);
        }
        navigate("/schema_dashboard", { replace: false });

        // else {
        //   setError(response.data.error)
        // }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.error,
        });
        if (error.response.data.data && error.response.data.data.fields) {
          setHighlights(error.response.data.data.fields.common);
          setSpecificHighlights(error.response.data.data.fields.specific);
        }
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <Navigation />
      <div>
        {isLoading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <Container>
            <LeftPanel
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              highlights={highlights}
              setHighlights={setHighlights}
              collections={collections}
            />
            <RightSection
              saveDataToAPI={saveDataToAPI}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              highlights={highlights}
              setHighlights={setHighlights}
              collections={collections}
              setCollections={setCollections}
              specificHighlights={specificHighlights}
              setSpecificHighlights={setSpecificHighlights}
            />
          </Container>
        )}
      </div>
    </div>
  );
}

export default SchemaBuilder;
