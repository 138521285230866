import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  MenuList,
  Select,
  Typography,
} from "@mui/material";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import { Colors } from "../theme";
import { FunctionsRounded } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import GetTrainingDataAPI from "../../api/GetTrainingData";
import SubmitTrainingDataAPI from "../../api/SubmitTrainingData";
import Swal from "sweetalert2";
import GetTransactionsListAPI from "../../api/GetTransactionsList";
import GetTransactionsExportDataAPI from "../../api/GetTransactionsExportData";
import exportToCsv from "../../utils/exportToCsv";
import { DownloadExcel } from "react-excel-export";

function CSVDialog(props) {
  const [error, setError] = React.useState("");
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false);
  const [exportedData, setExportedData] = React.useState([]);
  const [downloadAs, setDownloadAs] = React.useState("EXCEL");

  React.useEffect(() => {
    setIsLoading(true);
    GetTransactionsListAPI({})
      .then((response) => {
        setIsLoading(false);
        console.log(response);
        setRows(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function downloadAsExcel() {
    setIsLoadingSubmit(true);
    GetTransactionsExportDataAPI({
      transactionIdList: selectionModel,
    })
      .then((response) => {
        setIsLoadingSubmit(false);
        setExportedData((exportedData) => response.data.data);
        document.getElementById("download-csv-button").click();
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  }
  function downloadAsJSON() {
    setIsLoadingSubmit(true);
    GetTransactionsExportDataAPI({
      transactionIdList: selectionModel,
    })
      .then((response) => {
        // create file in browser
        const fileName = "export";
        const json = JSON.stringify(response.data.data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  }

  function downloadAsCSV() {
    setIsLoadingSubmit(true);
    GetTransactionsExportDataAPI({
      transactionIdList: selectionModel,
    })
      .then((response) => {
        setIsLoadingSubmit(false);
        console.log(response);
        console.log(Object.keys(response.data.data[0]));
        let newData = [Object.keys(response.data.data[0])];
        response.data.data.forEach((element) => {
          newData.push(Object.values(element));
        });
        console.log(newData);
        exportToCsv("data.csv", newData);
      })
      .catch((error) => {
        console.log(error.message);
        setError(error.message);
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  }

  function downloadData() {
    if (downloadAs === "EXCEL") {
      downloadAsExcel();
    }
    if (downloadAs === "CSV") {
      downloadAsCSV();
    }
    if (downloadAs === "JSON") {
      downloadAsJSON();
    }
  }

  const columns = [
    { field: "transactionId", headerName: "Transaction ID", flex: 1 },
    { field: "recordNumber", headerName: "Record Number", flex: 1 },
    { field: "reference", headerName: "Referece", flex: 1 },
    { field: "dateAdded", headerName: "Date Added", flex: 1 },
    { field: "itemsAvailable", headerName: "Items Available", flex: 1 },
    { field: "statusRPA", headerName: "Status RPA", flex: 1 },
    { field: "statusML", headerName: "Status ML", flex: 1 },
    { field: "statusChange", headerName: "Status Change", flex: 1 },
    { field: "recordReference", headerName: "Record Reference", flex: 1 },
    { field: "Tenant", headerName: "Tenant", flex: 1 },
    { field: "TenantId", headerName: "Tenant ID", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1 },
    { field: "endDate", headerName: "End Date", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
  ];

  const [rows, setRows] = React.useState([]);

  return (
    <Dialog
      open={true}
      onClose={() => props.setOpen(false)}
      style={{ zIndex: 10000 }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1366px", // Set your width here
          },
        },
      }}
    >
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div> Download/Export Data</div>
        <FormControl
          style={{
            display: "flex",
            gap: "8px",
            flexDirection: "row",
            alignItems: "center",
          }}
          size="small"
        >
          <label style={{ fontSize: "1rem", fontWeight: 400 }}>Export To</label>
          <Select
            style={{ marginTop: "0px" }}
            value={downloadAs}
            onChange={(e) => setDownloadAs(e.target.value)}
            MenuProps={{
              style: { zIndex: 35001 },
            }}
            variant="standard"
          >
            <MenuItem value={"EXCEL"}>EXCEL</MenuItem>
            <MenuItem value={"CSV"}>CSV</MenuItem>
            <MenuItem value={"JSON"}>JSON</MenuItem>
          </Select>
        </FormControl>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          checkboxSelection
          getRowId={(row) => row.transactionId}
          onSelectionModelChange={(newModel) => {
            setSelectionModel(newModel);
          }}
          selectionModel={selectionModel}
          density="compact"
          loading={isLoading}
        />

        {error && (
          <Alert
            severity="error"
            style={{ marginTop: "16px" }}
            sx={{ width: "360px" }}
          >
            {error}
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} color="inverse">
          Cancel
        </Button>
        <Button onClick={(e) => downloadData()}>
          {" "}
          {isLoadingSubmit ? (
            <CircularProgress color="inherit" size={23} />
          ) : (
            "Download"
          )}
        </Button>
        <DownloadExcel
          invisible={true}
          data={exportedData}
          buttonLabel="Export Data"
          fileName="export"
          itemKey={"download-csv-button"}
        />
      </DialogActions>
    </Dialog>
  );
}

export default CSVDialog;
