import React, { useRef } from "react";
import {
  Highlight,
  PdfLoader,
  Popup,
  Tip,
  AreaHighlight,
  PdfHighlighter,
} from "../../utils/react-pdf-highlighter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "../theme";

const SideBar = styled("div")(({ theme }) => ({
  height: "100%",
  width: "25%",
  padding: "24px",
}));

function TagDocuments(props) {
  // console.log(window.location.search.split("?url=")[1])
  const [url, setUrl] = React.useState("https://arxiv.org/pdf/1708.08021.pdf");
  const [selectedAccordion, setSelectedAccordion] =
    React.useState("common_fields");
  const HighlightPopup = ({ comment }) =>
    comment.text ? (
      <div className="Highlight__popup">{comment.text}</div>
    ) : null;
  const getNextId = () => String(Math.random()).slice(2);

  function updateHighlight(highlight) {
    console.log(highlight);
    let newHighlightsArr = props.highlights.filter(
      (item) => item.comment.text !== highlight.comment.text
    );

    newHighlightsArr.splice(highlight.comment.index, 0, {
      ...highlight,
      id: getNextId(),
    });
    props.setHighlights(newHighlightsArr);
  }

  //Replaced stock useRef with normal ref (react-pdf-highlighter prop)
  const pdfHighlighter = useRef(null);
  const getHighlightById = (id) =>
    props.highlights.find((highlight) => highlight.id === id);
  const scrollToHighlightFromHash = (hash) => {
    const highlight = getHighlightById(hash);
    if (highlight) {
      pdfHighlighter.current.scrollTo(highlight);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          height: "calc(100vh - 169px)",
          width: "75%",
          position: "relative",
        }}
      >
        <PdfLoader url={url} beforeLoad={<CircularProgress />}>
          {(pdfDocument) => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              enableAreaSelection={(event) => event.altKey}
              ref={pdfHighlighter}
              onScrollChange={() => {}}
              onSelectionFinished={(
                position,
                content,
                hideTipAndSelection,
                transformSelection
              ) => (
                <Tip
                  highlightsArr={props.highlights}
                  onOpen={transformSelection}
                  onConfirm={(comment) => {
                    // addHighlight({ content, position, comment });
                    updateHighlight({ content, position, comment });
                    console.log(content, position, comment);
                    hideTipAndSelection();
                  }}
                />
              )}
              // onSelectionFinished={(position, content, hideTipAndSelection, transformSelection) => (<Tip onOpen={transformSelection} onConfirm={(comment) => {
              //     addHighlight({ content, position, comment });
              //     console.log(content, position, comment)
              //     console.log(highlights)
              //     hideTipAndSelection();
              // }} />)}
              highlightTransform={(
                highlight,
                index,
                setTip,
                hideTip,
                viewportToScaled,
                screenshot,
                isScrolledTo
              ) => {
                const isTextHighlight = !Boolean(
                  highlight.content && highlight.content.image
                );
                const component = isTextHighlight ? (
                  <Highlight
                    isScrolledTo={isScrolledTo}
                    position={highlight.position}
                    comment={highlight.comment}
                  />
                ) : (
                  <AreaHighlight
                    isScrolledTo={isScrolledTo}
                    highlight={highlight}
                    onChange={(boundingRect) => {
                      this.updateHighlight(
                        highlight.id,
                        { boundingRect: viewportToScaled(boundingRect) },
                        { image: screenshot(boundingRect) }
                      );
                    }}
                  />
                );
                return (
                  <Popup
                    popupContent={<HighlightPopup {...highlight} />}
                    onMouseOver={(popupContent) =>
                      setTip(highlight, (highlight) => popupContent)
                    }
                    onMouseOut={hideTip}
                    key={index}
                    children={component}
                  />
                );
              }}
              highlights={props.highlights.filter((item) => item.position)}
            />
          )}
        </PdfLoader>
      </div>
      <SideBar>
        <h3 style={{ marginBottom: "16px" }}>Documents</h3>

        {props.documents &&
          props.documents.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                console.log(item);
                //   item.position && scrollToHighlightFromHash(item.id);
              }}
              style={{
                cursor: item.position ? "pointer" : "default",
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
                gap: "8px",
              }}
            >
              {item.position ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : (
                <CheckCircleIcon style={{ color: "grey" }} />
              )}
              {item.name}
            </div>
          ))}
        <Divider />
        <h3 style={{ marginBottom: "16px", marginTop: "16px" }}>
          List of fields
        </h3>
        <Accordion
          expanded={selectedAccordion === "common_fields"}
          style={{
            border: "2px solid " + Colors.info,
            borderRadius: "0px",
            marginBottom: "8px",
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setSelectedAccordion("common_fields")}
          >
            <Typography>Common Highlights</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props.highlights.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  console.log(item);
                  item.position && scrollToHighlightFromHash(item.id);
                }}
                style={{
                  cursor: item.position ? "pointer" : "default",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  gap: "8px",
                }}
              >
                {item.position ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <CheckCircleIcon style={{ color: "grey" }} />
                )}
                {item.comment.text}
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
        {props.specificHighlights.map((item, index) => (
          <Accordion
            expanded={selectedAccordion === item.name}
            style={{
              border: "2px solid " + Colors.info,
              borderRadius: "0px",
              marginBottom: "8px",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => setSelectedAccordion(item.name)}
            >
              <Typography>{item.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.highlights.map(
                (specificHighlight, specificHighlightIndex) => (
                  <div
                    key={specificHighlightIndex}
                    //   onClick={() => {
                    //     console.log(item);
                    //     item.position && scrollToHighlightFromHash(item.id);
                    //   }}
                    style={{
                      cursor: specificHighlight.position
                        ? "pointer"
                        : "default",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      gap: "8px",
                    }}
                  >
                    {specificHighlight.position ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CheckCircleIcon style={{ color: "grey" }} />
                    )}
                    {specificHighlight.comment.text}
                  </div>
                )
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </SideBar>
    </div>
  );
}

export default TagDocuments;
