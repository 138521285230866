import styled from '@emotion/styled'
import { Alert, Avatar, Backdrop, Button, CircularProgress, Divider, MenuItem, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import Navigation from '../../sharedComponents/Navigation/Navigation'
import Sidebar from '../../sharedComponents/Sidebar/Sidebar'
import { Country, State, City } from 'country-state-city';
import { Email } from '@mui/icons-material'
import GetProfileDataAPI from '../../api/GetProfileData'
import getBase64 from '../../utils/getBase64'
import SetProfileDataAPI from '../../api/SetProfileData'



const Root = styled('div')(({ theme }) => ({

}))
const Container = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '240px auto'
}))

const Input = styled('input')({
    display: 'none',
});


function Profile() {
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [address, setAddress] = React.useState("")
    const [country, setCountry] = React.useState('IN')
    const [state, setState] = React.useState("MH")
    const [city, setCity] = React.useState("Pune")
    const [contactNumber, setContactNumber] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [profilePic, setProfilePic] = React.useState(null)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLoadingData, setIsLoadingData] = React.useState(true)
    const [error, setError] = React.useState("")

    React.useEffect(() => {
        setIsLoadingData(true)
        GetProfileDataAPI().then(response => {
            setFirstName(response.data.firstName)
            setLastName(response.data.lastName)
            setAddress(response.data.address)
            setCountry(response.data.country)
            setState(response.data.state)
            setCity(response.data.city)
            setContactNumber(response.data.phoneNumber)
            setEmail(response.data.email)
            setProfilePic(response.data.profilePic)
            console.log(response.data)
            // setRows(response.data)
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setIsLoadingData(false)
        })
    }, [])

    function updateProfile() {
        setError('')
        setIsLoading(true)
        SetProfileDataAPI({
            fname: firstName,
            lname: lastName,
            address: address,
            city: city,
            country: country,
            phone: contactNumber,
            profilepic: profilePic
        }).then(response => {
            if (!response.data.successResponse) {
                setError(response.data.error)
            }
        }).catch(error => {
            setError(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    return (
        <Root>
            <Navigation />

            <Container>
                <Sidebar />
                {
                    isLoadingData ? <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop> : <div>
                        <div style={{ padding: '32px' }}>
                            <Typography variant='h4'>My Profile</Typography>
                        </div>
                        <div style={{ maxWidth: '960px', margin: '0 auto', display: 'grid', gridTemplateColumns: '1fr auto', gap: '64px', marginTop: '32px' }}>
                            <div>
                                <Typography variant="h5" gutterBottom>Profile Details</Typography>
                                <div>
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
                                        <TextField
                                            margin="dense"
                                            label="First Name"
                                            value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        />
                                        <TextField
                                            margin="dense"
                                            label="Last Name"
                                            value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        />


                                    </div>
                                    <TextField

                                        margin="dense"
                                        multiline
                                        value={address}
                                        onChange={e => setAddress(e.target.value)}
                                        label="Address"
                                        inputProps={{
                                            style: {
                                                fontSize: '14px'
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: '14px'
                                            }
                                        }}
                                        fullWidth
                                        variant="standard"
                                    />
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
                                        <TextField
                                            margin="dense"
                                            label="Country"
                                            select
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        >
                                            {Country.getAllCountries().map((option) => (
                                                <MenuItem key={option.isoCode} value={option.isoCode}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            margin="dense"
                                            label="State"
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            select
                                            fullWidth
                                            variant="standard"
                                        >
                                            {State.getStatesOfCountry(country).map((option) => (
                                                <MenuItem key={option.isoCode} value={option.isoCode}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>

                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
                                        <TextField
                                            margin="dense"
                                            label="City"
                                            select
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        >
                                            {City.getCitiesOfState(country, state).map((option) => (
                                                <MenuItem key={option.name} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </div>

                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '24px' }}>
                                        <TextField
                                            margin="dense"
                                            label="Contact Number"
                                            value={contactNumber}
                                            onChange={e => setContactNumber(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        />
                                        <TextField
                                            margin="dense"
                                            label="Email"
                                            disabled
                                            value={email}
                                            type="email"
                                            inputProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontSize: '14px'
                                                }
                                            }}
                                            fullWidth
                                            variant="standard"
                                        />
                                    </div>

                                    {
                                        error && <Alert severity="error" style={{ marginTop: '16px' }}>{error}</Alert>

                                    }

                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                                        <Button variant='contained' disableElevation onClick={e => updateProfile()}>{
                                            isLoading ? <CircularProgress color="inherit" size={23} /> : "SAVE"
                                        }</Button>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'grid', placeItems: 'center' }}>
                                <Paper elevation={2} style={{ padding: '16px', width: '256px' }}>
                                    <Typography variant='h6' gutterBottom>Profile Image</Typography>
                                    <Divider style={{ marginBottom: '24px' }} />
                                    <div style={{ display: 'grid', justifyItems: 'center' }}>
                                        <Avatar alt={`${firstName} ${lastName}`} src={profilePic} style={{ height: '128px', width: '128px' }} />
                                        <Typography variant="h6" style={{ marginTop: '8px' }} gutterBottom>{`${firstName} ${lastName}`}</Typography>

                                    </div>
                                    <Divider orientation='horizontal' />
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <label htmlFor="contained-button-file" style={{ marginTop: '12px' }}>
                                            <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={e => {
                                                getBase64(e.target.files[0], (result) => {
                                                    setProfilePic(result)
                                                })
                                            }} />
                                            <Button component="span">
                                                Change
                                            </Button>
                                        </label>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                }


            </Container>
        </Root>
    )
}

export default Profile




