import axios from "axios";
import { APIURL } from "../config";

const LoginApi = async (user) => {
    const url = APIURL + "/account/login"
    return await axios.post(url, user)
}

export const LoginMS = async (user) => {

    const url = APIURL + "/account/loginms"

    return await axios.post(url, user)

}

export default LoginApi