import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { Colors } from '../theme'
import { FunctionsRounded } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import GetTrainingDataAPI from '../../api/GetTrainingData';
import SubmitTrainingDataAPI from '../../api/SubmitTrainingData';
import Swal from 'sweetalert2';




function TrainingDialog(props) {
    const [error, setError] = React.useState('')
    const [selectionModel, setSelectionModel] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [isLoadingSubmit, setIsLoadingSubmit] = React.useState(false)
    console.log(props)

    React.useEffect(() => {
        setIsLoading(true)
        GetTrainingDataAPI({
            schemaDefinitionId: props.selectedTrainingModel.schemaDefinitionId
        }).then(response => {
            setIsLoading(false)
            console.log(response)
            setRows(response.data)
        }).catch(error => {
            console.log(error.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    function submitData() {
        setIsLoadingSubmit(true)
        SubmitTrainingDataAPI({
            schemaId: props.selectedTrainingModel.schemaDefinitionId,
            transactionIdList: selectionModel
        }).then(response => {
            setIsLoadingSubmit(false)
            console.log(response)
            Swal.fire(
                'Done!',
                'Data submitted successfully!',
                'success'
            ).then((result) => {
                props.setOpen(false)
            })
        }).catch(error => {
            console.log(error.message)
            setError(error.message)
        }).finally(() => {
            setIsLoadingSubmit(false)

        })

    }

    const columns = [
        { field: "transactionId", headerName: 'Transaction ID', flex: 1 },
        { field: "recordNumber", headerName: 'Record Number', flex: 1 },
        { field: "reference", headerName: 'Referece', flex: 1 },
        { field: "dateAdded", headerName: 'Date Added', flex: 1 },
        { field: "itemsAvailable", headerName: 'Items Available', flex: 1 },
        { field: "statusRPA", headerName: 'Status RPA', flex: 1 },
        { field: "statusML", headerName: 'Status ML', flex: 1 },
        { field: "statusChange", headerName: 'Status Change', flex: 1 },
        { field: "recordReference", headerName: 'Record Reference', flex: 1 },
        { field: "Tenant", headerName: 'Tenant', flex: 1 },
        { field: "TenantId", headerName: 'Tenant ID', flex: 1 },
        { field: "startDate", headerName: 'Start Date', flex: 1 },
        { field: "endDate", headerName: 'End Date', flex: 1 },
        { field: "status", headerName: 'Status', flex: 1 },

    ];

    const [rows, setRows] = React.useState([])




    return (
        <Dialog open={true} onClose={() => props.setOpen(false)} style={{ zIndex: 10000 }}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1366px",  // Set your width here
                    },
                },
            }}>
            <DialogTitle>Start Training</DialogTitle>
            <Divider />
            <DialogContent >
                <DataGrid
                    autoHeight

                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    getRowId={(row) => row.transactionId}
                    onSelectionModelChange={newModel => {
                        setSelectionModel(newModel)
                    }}
                    selectionModel={selectionModel}
                    density="compact"
                    loading={isLoading}
                />

                {
                    error && <Alert severity="error" style={{ marginTop: '16px' }} sx={{ width: '360px' }}>{error}</Alert>
                }
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => props.setOpen(false)} color="inverse">Cancel</Button>
                <Button onClick={e => submitData()}>                        {isLoadingSubmit ? <CircularProgress color="inherit" size={23} /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TrainingDialog