import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Alert,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { PropaneSharp } from "@mui/icons-material";
import CreateUserAPI from "../../../api/CreateUser";

function AddUserDialog(props) {
  const [username, setUsername] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const [amCompleted, setAmCompleted] = React.useState(false);
  const [amPending, setAmPending] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [underReview, setUnderReview] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [archived, setArchived] = React.useState(false);
  const [assignedTo, setAssignedTo] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleChangeRoles = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function handleCreateUser(e) {
    e.preventDefault();
    console.log("Submit");
    if (password !== confirmPassword) {
      setError("Password and confirm password has to be the same!");
    } else {
      setError("");
      setIsLoading(true);
      CreateUserAPI({
        username: username,
        fname: firstName,
        lname: lastName,
        password: password,
        email: email,
        role: roles,
        amPending: amPending,
        amCompleted: amCompleted,
        pending: pending,
        underReview: underReview,
        completed: completed,
        archived: archived,
        assignedTo: assignedTo,
      })
        .then((response) => {
          console.log(response);
          if (response.data.successResponse == false)
            setError(response.data.error);
          else {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log("Error", error);
          setError(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <Dialog open={props.openDiag} onClose={() => props.setOpenDiag(false)}>
      <form onSubmit={(e) => handleCreateUser(e)}>
        <DialogTitle>Add User</DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: "0px 24px 24px 24px" }}>
          <div style={{ width: "360px" }}>
            <TextField
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
              margin="dense"
              label="Username"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              fullWidth
              variant="standard"
            />
            <TextField
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="dense"
              label="First Name"
              fullWidth
              variant="standard"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="dense"
              label="Last Name"
              fullWidth
              variant="standard"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="dense"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              required
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError("");
              }}
              margin="dense"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              required
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError("");
              }}
              margin="dense"
              label="Confirm Password"
              type="password"
              fullWidth
              variant="standard"
              inputProps={{
                style: {
                  fontSize: "14px",
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: "14px",
                },
              }}
            />
            <FormControl
              fullWidth
              variant="standard"
              style={{ marginTop: "6px" }}
              required
            >
              <InputLabel style={{ fontSize: "14px" }}>Roles</InputLabel>
              <Select
                multiple
                value={roles}
                onChange={handleChangeRoles}
                renderValue={(selected) => selected.join(", ")}
                fullWidth
                size="small"
                inputProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
                style={{ fontSize: "14px" }}
              >
                {props.allRoles.map((name) => (
                  <MenuItem key={name} value={name} style={{ padding: "0px" }}>
                    <Checkbox checked={roles.indexOf(name) > -1} />
                    <ListItemText
                      primary={<span style={{ fontSize: "14px" }}>{name}</span>}
                      style={{ fontSize: "14px" }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <fieldset
              style={{
                padding: "0px 8px 8px 16px",
                border: "2px solid lightgrey",
                marginTop: "8px",
              }}
            >
              <legend
                style={{
                  padding: "0px 8px",
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "grey",
                }}
              >
                Access
              </legend>
              <div style={{ display: "grid" }}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={amCompleted}
                        onChange={(e) => setAmCompleted(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Account Manager Completed Access
                      </Typography>
                    }
                  />
                </FormControl>

                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={amPending}
                        onChange={(e) => setAmPending(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Account Manager Pending Access
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={pending}
                        onChange={(e) => setPending(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Pending Access
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={underReview}
                        onChange={(e) => setUnderReview(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Review Access
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={completed}
                        onChange={(e) => setCompleted(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Completed Access
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={archived}
                        onChange={(e) => setArchived(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        Archived Access
                      </Typography>
                    }
                  />
                </FormControl>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={assignedTo}
                        onChange={(e) => setAssignedTo(e.target.checked)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        AssignedTo Access
                      </Typography>
                    }
                  />
                </FormControl>
              </div>
            </fieldset>
          </div>
          {error && (
            <Alert
              severity="error"
              style={{ marginTop: "16px" }}
              sx={{ width: "360px" }}
            >
              {error}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={() => props.setOpenDiag(false)} color="inverse">
            Cancel
          </Button>
          <Button type="submit">
            {" "}
            {isLoading ? (
              <CircularProgress color="inherit" size={23} />
            ) : (
              "Add User"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddUserDialog;
