import styled from "@emotion/styled";
import { Button, IconButton, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import Navigation from "../../../sharedComponents/Navigation/Navigation";
import Sidebar from "../../../sharedComponents/Sidebar/Sidebar";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddUserDialog from "./AddUserDialog";
import DeleteUserDialog from "./DeleteUserDialog";
import EditUserDialog from "./EditUserDialog";
import GetAllUsers from "../../../api/GetAllUsers";
import qs from "query-string";
import { useNavigate, createSearchParams } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({}));
const Container = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "240px auto",
}));

// const rows = [
//     {
//         id: 1,
//         name: "Aakash Varma",
//         email: "aakash.varma@innowise.us",
//         roles: "Admin",
//         isActive: true,
//     },

// ]

function ManageUsers() {
  const navigate = useNavigate();

  const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState(false);
  const [showEditUserDialog, setShowEditUserDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [allRoles, setAllRoles] = React.useState([]);
  const [selectedActionUser, setSelectedActionUser] = React.useState("");

  const [paginationData, setPaginationData] = React.useState({
    rowsPerPage: 15,
    rowCount: 0,
    page: 0,
  });

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    GetAllUsers({
      pagination:
        !isNaN(qs.parse(window.location.search).rowsPerPage) &&
        !isNaN(
          parseInt(qs.parse(window.location.search).rowCount) &&
            !isNaN(qs.parse(window.location.search).page)
        )
          ? {
              rowsPerPage: parseInt(
                qs.parse(window.location.search).rowsPerPage
              ),
              rowCount: parseInt(qs.parse(window.location.search).rowCount),
              page: parseInt(qs.parse(window.location.search).page),
            }
          : paginationData,
      filtertext: "",
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.error) {
        } else {
          setRows(response.data.result);
          setAllRoles(response.data.availableRoles);
          setPaginationData(response.data.pagination);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [window.location.search]);

  const columns = [
    {
      field: "userName",
      headerName: "Username",
      width: 260,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {params.value}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Name
          </span>
        );
      },
    },
    { field: "email", headerName: "Email", width: 260 },
    { field: "roles", headerName: "Roles", width: 130 },
    { field: "isActive", headerName: "Active", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              onClick={() => {
                setSelectedActionUser(params.row);
                setShowEditUserDialog(true);
              }}
            >
              <CreateOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px", marginRight: "16px" }}
              onClick={() => {
                setShowDeleteUserDialog(true);
                setSelectedActionUser(params.row);
              }}
            >
              <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
          </span>
        );
      },
    },
  ];

  return (
    <Root>
      <Navigation />
      <AddUserDialog
        openDiag={showAddUserDialog}
        setOpenDiag={setShowAddUserDialog}
        allRoles={allRoles}
      />
      <DeleteUserDialog
        openDiag={showDeleteUserDialog}
        setOpenDiag={setShowDeleteUserDialog}
        user={selectedActionUser}
      />
      <EditUserDialog
        openDiag={showEditUserDialog}
        setOpenDiag={setShowEditUserDialog}
        allRoles={allRoles}
        user={selectedActionUser}
      />

      <Container>
        <div style={{ minHeight: "calc(100vh-52px)" }}>
          <Sidebar />
        </div>
        <div style={{ padding: "24px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <Typography variant="h5" component="div">
              Manage Users
            </Typography>
            <Button
              onClick={() => setShowAddUserDialog(true)}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                "&:hover": { borderWidth: "2px" },
              }}
            >
              Add
            </Button>
          </div>

          <DataGrid
            sx={{ border: "none" }}
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu
            paginationMode="server"
            rowCount={paginationData.rowCount}
            page={parseInt(qs.parse(window.location.search).page) || 0}
            // hideFooterPagination={true}
            autoHeight
            disableSelectionOnClick
            onPageChange={(data) => {
              let currentQuery = qs.parse(window.location.search);
              currentQuery.page = data;
              currentQuery.rowsPerPage = paginationData.rowsPerPage;
              currentQuery.rowCount = paginationData.rowCount;
              const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(currentQuery)}`,
              };
              navigate(options, { replace: false });
            }}
            onPageSizeChange={(data) => {
              let currentQuery = qs.parse(window.location.search);
              currentQuery.page = paginationData.page;
              currentQuery.rowsPerPage = data;
              currentQuery.rowCount = paginationData.rowCount;
              const options = {
                pathname: window.location.pathname,
                search: `?${createSearchParams(currentQuery)}`,
              };
              navigate(options, { replace: false });
            }}
            pageSize={paginationData.rowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
          />
        </div>
      </Container>
    </Root>
  );
}

export default ManageUsers;
