import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Divider } from '@mui/material';
import {
    Checkbox,

    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
} from "@mui/material";

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Colors } from "../theme";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeIcon from "@mui/icons-material/DateRange";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import GetGenerateReportAPI from '../../api/GetGenerateReport';

export default function ReportDialog(props) {
    const { statusFilterBy } = props
    const [selectedStatusFilterBy, setSelectedStatusFilterBy] = React.useState([])
    const [dateAdded, setDateAdded] = React.useState(null)
    const [startDate, setStartDate] = React.useState(null)
    const [endDate, setEndDate] = React.useState(null)
    const [lastModifiedDate, setLastModifiedDate] = React.useState(null)
    const printComponentRef = useRef();
    const [isLoading, setIsLoading] = React.useState(false)
    const [apiResponse, setApiResponse] = React.useState(null)


    const tableData = [
        {
            status: "Pending",
            count: 20,
            assignedTo: 'N/A',
        },
        {
            status: "Pending",
            count: 15,
            assignedTo: 'sue',
        },
        {
            status: "Completed",
            count: 25,
            assignedTo: 'rpabot',
        }
    ]

    const tableData2 = [
        {
            info: 'Changes',
            pending: 20,
            success: "N/A",
            faulted: ""
        }
    ]


    const handleClose = () => {
        props.setOpen(false);
    };



    const [anchorElFilter, setAnchorElFilter] = React.useState(null);
    React.useState(null);
    const [anchorElStatusFilter, setAnchorElStatusFilter] = React.useState(null);
    const [anchorElDateRange, setAnchorElDateRange] = React.useState(null);
    const [anchorElStartDateRange, setAnchorElStartDateRange] =
        React.useState(null);
    const [anchorElLastModifiedDateRange, setAnchorElLastModifiedDateRange] =
        React.useState(null);
    const [anchorElEndDateRange, setAnchorElEndDateRange] = React.useState(null);


    const handleClickStatusFilter = (event) => {
        setAnchorElStatusFilter(event.currentTarget);
    };



    const handleCloseStatusFilter = () => {
        setAnchorElStatusFilter(null);
    };

    const handleClickDateRange = (event) => {
        setAnchorElDateRange(event.currentTarget);
    };

    const handleCloseDateRange = () => {
        setAnchorElDateRange(null);
    };

    const handleClickStartDateRange = (event) => {
        console.log(event);
        setAnchorElStartDateRange(event.currentTarget);
    };

    const handleCloseStartDateRange = () => {
        setAnchorElStartDateRange(null);
    };
    const handleClickLastModifiedDateRange = (event) => {
        console.log(event);
        setAnchorElLastModifiedDateRange(event.currentTarget);
    };

    const handleCloseLastModifiedDateRange = () => {
        setAnchorElLastModifiedDateRange(null);
    };

    const handleClickEndDateRange = (event) => {
        setAnchorElEndDateRange(event.currentTarget);
    };

    const handleCloseEndDateRange = () => {
        setAnchorElEndDateRange(null);
    };


    const openFilter = Boolean(anchorElFilter);

    const openLastModifiedDateRange = Boolean(anchorElLastModifiedDateRange);

    const openStatusFilter = Boolean(anchorElStatusFilter);
    const idStatusFilter = openStatusFilter ? "status-filter-popover" : undefined;
    const idLastModifiedDateRange = openLastModifiedDateRange ? "lastmodifieddate-popover" : undefined;

    const openDateRange = Boolean(anchorElDateRange);
    const openStartDateRange = Boolean(anchorElStartDateRange);
    const openEndDateRange = Boolean(anchorElEndDateRange);
    const idDateRange = openFilter ? "daterabge-popover" : undefined;
    const idStartDateRange = openStartDateRange ? "startdate-popover" : undefined;
    const idEndDateRange = openEndDateRange ? "enddate-popover" : undefined;



    function handleStatusFilterChecked(item, index) {
        if (selectedStatusFilterBy.includes(item)) {
            setSelectedStatusFilterBy((selectedStatusFilterBy) =>
                selectedStatusFilterBy.filter((element) => element !== item)
            );
        } else {
            setSelectedStatusFilterBy([...selectedStatusFilterBy, item]);
        }
    }

    function getFieldName(fieldName) {
        switch (fieldName) {
            case "total":
                return "Total";
            case "completed":
                return "Total Completed";
            case "am pending":
                return "Total AM Pending";
            case "am completed":
                return "Total AM Completed";
            case "archived":
                return "Total Archived";
            case "pending":
                return "Total Pending";
            case "under review":
                return "Total Under Review";
            default:
                return fieldName
        }
    }



    function handleGenerateReport() {
        setIsLoading(true)
        let requestObj = {}
        if (selectedStatusFilterBy.length > 0) {
            requestObj = { ...requestObj, status: selectedStatusFilterBy }
        }
        if (dateAdded) {
            requestObj = { ...requestObj, dateRange: dateAdded }
        }
        if (startDate) {
            requestObj = { ...requestObj, startDateRange: startDate }
        }
        if (endDate) {
            requestObj = { ...requestObj, endDateRange: endDate }
        }
        if (lastModifiedDate) {
            requestObj = { ...requestObj, lastModifiedAtDateRange: lastModifiedDate }
        }
        GetGenerateReportAPI(
            requestObj
        ).then((response) => {
            console.log(response)
            setApiResponse(response.data.result)
        })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false)
            });

    }

    return (
        <div>

            <Dialog
                maxWidth={false}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Generate Report
                </DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '640px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div >
                            <Tooltip title={"Filter by Status"}>
                                <IconButton
                                    onClick={handleClickStatusFilter}
                                    style={{
                                        padding: "2px",
                                        background: Colors.body_bg,
                                        height: "38px",
                                        width: "38px",
                                        borderRadius: "50%",
                                        marginRight: "8px",
                                    }}
                                    color="primary"
                                    component="span"
                                >
                                    <AssistantPhotoIcon
                                        color={
                                            selectedStatusFilterBy.length > 0
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Filter by Date"} >
                                <IconButton
                                    onClick={handleClickDateRange}
                                    style={{
                                        padding: "2px",
                                        background: Colors.body_bg,
                                        height: "38px",
                                        marginRight: '8px',
                                        width: "38px",
                                        borderRadius: "50%",
                                    }}
                                    color="primary"
                                    component="span"
                                >
                                    <DateRangeIcon
                                        color={
                                            dateAdded
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Filter by Start Date"}>
                                <IconButton
                                    onClick={handleClickStartDateRange}
                                    style={{
                                        padding: "2px",
                                        background: Colors.body_bg,
                                        marginRight: '8px',

                                        height: "38px",
                                        width: "38px",
                                        borderRadius: "50%",
                                    }}
                                    color="primary"
                                    component="span"
                                >
                                    <EventAvailableRoundedIcon
                                        color={
                                            startDate
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={"Filter by End Date"}>
                                <IconButton
                                    onClick={handleClickEndDateRange}
                                    style={{
                                        padding: "2px",
                                        background: Colors.body_bg,
                                        height: "38px",
                                        width: "38px",
                                        borderRadius: "50%",
                                        marginRight: '8px',

                                    }}
                                    color="primary"
                                    component="span"
                                >
                                    <EventBusyRoundedIcon
                                        color={
                                            endDate
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={"Filter by Last Modified Date"}>
                                <IconButton
                                    onClick={handleClickLastModifiedDateRange}
                                    style={{
                                        padding: "2px",
                                        background: Colors.body_bg,
                                        height: "38px",
                                        width: "38px",
                                        borderRadius: "50%",
                                        marginRight: '8px',

                                    }}
                                    color="primary"
                                    component="span"
                                >
                                    <EventRepeatIcon
                                        color={
                                            lastModifiedDate
                                                ? "primary"
                                                : "disabled"
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Button
                                variant="outlined"
                                startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <AssessmentOutlinedIcon />}
                                sx={{
                                    borderRadius: "2px",
                                    borderWidth: "2px",
                                    padding: "4px 16px",
                                    marginRight: "8px",
                                    "&:hover": { borderWidth: "2px" },
                                }}
                                onClick={() => {
                                    handleGenerateReport()
                                }}
                            >
                                Generate Report
                            </Button>
                        </div>
                    </div>


                    {
                        apiResponse &&
                        <React.Fragment>
                            <Divider style={{ marginTop: '16px' }} />
                            <div ref={printComponentRef}>
                                <div>
                                    <div style={{ display: 'flex', marginTop: '16px', width: '100%', justifyContent: 'space-around' }}>
                                        {
                                            apiResponse.totalData.map((item, index) => (
                                                <div style={{ width: '200px', textAlign: 'center', padding: '5px 5px' }} key={index}>
                                                    <h3 >{getFieldName(item.fieldName)}</h3>
                                                    <span style={{ color: Colors.primary, fontSize: '1.4rem', fontWeight: 600 }}>{item.count}</span>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                                <div style={{ marginTop: '16px', display: 'grid', gridTemplateColumns: "1fr 1fr", gap: '24px' }}>

                                    <div>
                                        <Table sx={{ minWidth: 350, width: '100%' }} size="small" >
                                            <TableHead>
                                                <TableRow style={{ background: '#f1f1f1' }}>
                                                    <TableCell style={{ fontWeight: 600 }}>Status</TableCell>
                                                    <TableCell align="right" style={{ fontWeight: 600 }}>Count</TableCell>
                                                    <TableCell align="right" style={{ fontWeight: 600 }}>Assigned To</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {apiResponse.filteredItems.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.status}
                                                        </TableCell>
                                                        <TableCell align="right">{row.count}</TableCell>
                                                        <TableCell align="right">{row.assignedTo}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>

                                    <div>
                                        <Table sx={{ minWidth: 350, width: '100%' }} size="small" >
                                            <TableHead>
                                                <TableRow style={{ background: '#f1f1f1' }}>
                                                    <TableCell style={{ fontWeight: 600 }}></TableCell>
                                                    <TableCell style={{ fontWeight: 600 }}>Pending</TableCell>
                                                    <TableCell align="right" style={{ fontWeight: 600 }}>Success</TableCell>
                                                    <TableCell align="right" style={{ fontWeight: 600 }}>Faulted</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {apiResponse.change.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.info}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row.pending}
                                                        </TableCell>
                                                        <TableCell align="right">{row.success}</TableCell>
                                                        <TableCell align="right">{row.faulted}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>


                                </div>
                            </div>
                        </React.Fragment>
                    }





                    {/* Status Filter */}
                    <Popover
                        elevation={1}
                        id={idStatusFilter}
                        open={openStatusFilter}
                        anchorEl={anchorElStatusFilter}
                        onClose={handleCloseStatusFilter}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <List component="nav" dense>
                            {statusFilterBy.map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton
                                        role={undefined}
                                        onClick={(e) => handleStatusFilterChecked(item, index)}
                                        dense
                                    >
                                        <ListItemIcon
                                            style={{ minWidth: "32px", height: "24px" }}
                                        >
                                            <Checkbox
                                                edge="start"
                                                checked={selectedStatusFilterBy.includes(item) ||
                                                    false
                                                }
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={index} primary={item} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                            <Divider style={{ marginBottom: "8px" }} />


                            <ListItem disablePadding>
                                <ListItemButton
                                    role={undefined}
                                    style={{ padding: "0px", margin: "0px" }}
                                    onClick={(e) => setSelectedStatusFilterBy([])}
                                    dense
                                >
                                    <ListItemText
                                        style={{ padding: "0px", margin: "0px" }}
                                        primary={
                                            <Button fullWidth size="small">
                                                Clear
                                            </Button>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Popover>

                    <Popover
                        elevation={1}
                        id={idDateRange}
                        open={openDateRange}
                        anchorEl={anchorElDateRange}
                        onClose={handleCloseDateRange}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <DateRangePicker
                                ranges={
                                    [
                                        dateAdded || {
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            key: "selection",
                                        },
                                    ]
                                }
                                onChange={(e) => {
                                    console.log(e)
                                    setDateAdded(e.selection)
                                }}
                            />
                            <Button
                                onClick={(e) => {
                                    console.log("Clear Selection")
                                    setDateAdded(null)

                                }}
                            >
                                Clear
                            </Button>
                        </div>
                    </Popover>

                    <Popover
                        elevation={1}
                        id={idStartDateRange}
                        open={openStartDateRange}
                        anchorEl={anchorElStartDateRange}
                        onClose={handleCloseStartDateRange}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <DateRangePicker
                                ranges={
                                    [
                                        startDate || {
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            key: "selection",
                                        },
                                    ]
                                }
                                onChange={(e) => {
                                    console.log(e.selection);
                                    setStartDate(e.selection)


                                }}
                            />
                            <Button
                                onClick={(e) => {
                                    console.log("Clear Selection")
                                    setStartDate(null)

                                }}
                            >
                                Clear
                            </Button>
                        </div>
                    </Popover>

                    <Popover
                        elevation={1}
                        id={idEndDateRange}
                        open={openEndDateRange}
                        anchorEl={anchorElEndDateRange}
                        onClose={handleCloseEndDateRange}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <DateRangePicker
                                ranges={
                                    [
                                        endDate || {
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            key: "selection",
                                        },
                                    ]
                                }
                                onChange={(e) => {
                                    console.log(e.selection);
                                    setEndDate(e.selection)


                                }}
                            />
                            <Button
                                onClick={(e) => {
                                    console.log("Clear Selection")
                                    setEndDate(null)

                                }}
                            >
                                Clear
                            </Button>
                        </div>
                    </Popover>


                    <Popover
                        elevation={1}
                        id={idLastModifiedDateRange}
                        open={openLastModifiedDateRange}
                        anchorEl={anchorElLastModifiedDateRange}
                        onClose={handleCloseLastModifiedDateRange}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <DateRangePicker
                                ranges={
                                    [
                                        lastModifiedDate || {
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            key: "selection",
                                        },
                                    ]
                                }
                                onChange={(e) => {
                                    console.log(e.selection);
                                    setLastModifiedDate(e.selection)


                                }}
                            />
                            <Button
                                onClick={(e) => {
                                    console.log("Clear Selection")
                                    setLastModifiedDate(null)

                                }}
                            >
                                Clear
                            </Button>
                        </div>
                    </Popover>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant='contained' elevation={0} color="inverse" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <ReactToPrint
                        trigger={() => <Button variant='contained' elevation={0}>
                            Print
                        </Button>}
                        content={() => printComponentRef.current}
                    />
                </DialogActions>

            </Dialog>
        </div>
    );
}