import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import dayjs, { Dayjs } from 'dayjs';
import exportToCsv from "../../utils/exportToCsv";

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Divider, FormControl, MenuItem, Select, TextField } from '@mui/material';
import {
    Checkbox,

    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
} from "@mui/material";

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Colors } from "../theme";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import DateRangeIcon from "@mui/icons-material/DateRange";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import EventBusyRoundedIcon from "@mui/icons-material/EventBusyRounded";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactToPrint from 'react-to-print';
import { useRef } from 'react';
import GetGenerateReportAPI from '../../api/GetGenerateReport';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CompletionReportAPI from '../../api/CompletionReportAPI';
import { DownloadExcel } from "react-excel-export";


export default function CompletionReportDialog(props) {

    const [startDate, setStartDate] = React.useState(dayjs(new Date()))
    const [endDate, setEndDate] = React.useState(dayjs(new Date()))
    const [isLoading, setIsLoading] = React.useState(false)
    const [apiResponse, setApiResponse] = React.useState(null)
    const [downloadAs, setDownloadAs] = React.useState("EXCEL");

    const blankData = [{

        "userOperationId": "",
        "operationStart": "",
        "operationEnd": "",
        "totalTimeInSeconds": "",
        "userName": "",
        "recordNumber": "",
        "reference": "",
        "status": ""

    }]






    const handleClose = () => {
        props.setOpen(false);
    };



    function handleGenerateReport() {
        setIsLoading(true)
        CompletionReportAPI(
            {
                startDate: (startDate.$M + 1) + "/" + startDate.$D + "/" + startDate.$y,
                endDate: (endDate.$M + 1) + "/" + endDate.$D + "/" + endDate.$y,
            }
        ).then((response) => {
            console.log(response)
            setApiResponse(response.data)
        })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false)
            });

    }

    function downloadData() {
        if (downloadAs === "EXCEL") {
            downloadAsExcel();
        }
        if (downloadAs === "CSV") {
            downloadAsCSV();
        }
        if (downloadAs === "JSON") {
            downloadAsJSON();
        }
    }

    function downloadAsJSON() {
        const fileName = "Report_Policy_Review_" + ((endDate.$M + 1) < 10 ? ("0" + (endDate.$M + 1)) : (endDate.$M + 1)) + "_" + (endDate.$D < 10 ? ("0" + endDate.$D) : endDate.$D) + "_" + endDate.$y;
        const json = JSON.stringify(apiResponse.data, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    function downloadAsCSV() {
        let newData = [apiResponse.headers];
        apiResponse.data.forEach((element) => {
            newData.push(Object.values(element));
        });
        console.log(newData);
        exportToCsv("Report_Policy_Review_" + ((endDate.$M + 1) < 10 ? ("0" + (endDate.$M + 1)) : (endDate.$M + 1)) + "_" + (endDate.$D < 10 ? ("0" + endDate.$D) : endDate.$D) + "_" + endDate.$y + ".csv", newData);
    }

    function downloadAsExcel() {
        document.getElementById("download-csv-button").click();
    }

    return (
        <div>

            <Dialog
                maxWidth={false}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Completion Report</div>
                    <FormControl
                        style={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        size="small"
                    >
                        <label style={{ fontSize: "1rem", fontWeight: 400 }}>Export To</label>
                        <Select
                            style={{ marginTop: "0px" }}
                            value={downloadAs}
                            onChange={(e) => setDownloadAs(e.target.value)}
                            MenuProps={{
                                style: { zIndex: 35001 },
                            }}
                            variant="standard"
                        >
                            <MenuItem value={"EXCEL"}>EXCEL</MenuItem>
                            <MenuItem value={"CSV"}>CSV</MenuItem>
                            <MenuItem value={"JSON"}>JSON</MenuItem>
                        </Select>
                    </FormControl>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '640px' }}>
                    <div>
                        <div style={{ display: 'flex', gap: '24px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { size: 'small', variant: 'standard' } }} label="Start Date" value={startDate} onChange={dt => {
                                    console.log(dayjs(dt.$d))
                                    setStartDate(dayjs(dt.$d))
                                }} />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { size: 'small', variant: 'standard' } }} label="End Date" value={endDate} onChange={dt => {
                                    console.log(dt.$d)
                                    setEndDate(dayjs(dt.$d))
                                }} />
                            </LocalizationProvider>
                        </div>
                        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end', }}>
                            <Button

                                variant="outlined"
                                startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <AssessmentOutlinedIcon />}
                                sx={{
                                    borderRadius: "2px",
                                    borderWidth: "2px",
                                    padding: "4px 16px",
                                    marginRight: "8px",
                                    "&:hover": { borderWidth: "2px" },
                                }}
                                onClick={() => {
                                    console.log("GEN REPORT")
                                    handleGenerateReport()
                                }}
                            >
                                Generate Report
                            </Button>
                        </div>
                    </div>



                    <div>
                        <Table sx={{ minWidth: 350, width: '100%', marginTop: '16px' }} size="small" >
                            <TableHead>
                                <TableRow style={{ background: '#f1f1f1' }}>
                                    <TableCell style={{ fontWeight: 600 }}>userOperationId</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>operationStart</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>operationEnd</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>totalTimeInSeconds</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>userName</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>recordNumber</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>reference</TableCell>
                                    <TableCell align="right" style={{ fontWeight: 600 }}>status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {apiResponse?.data && apiResponse.data.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.userOperationId}
                                        </TableCell>
                                        <TableCell align="right">{row.operationStart}</TableCell>
                                        <TableCell align="right">{row.operationEnd}</TableCell>
                                        <TableCell align="right">{row.totalTimeInSeconds}</TableCell>
                                        <TableCell align="right">{row.userName}</TableCell>
                                        <TableCell align="right">{row.recordNumber}</TableCell>
                                        <TableCell align="right">{row.reference}</TableCell>
                                        <TableCell align="right">{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>










                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant='contained' elevation={0} color="inverse" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button variant='contained' elevation={0} color="primary" onClick={() => downloadData()} disabled={!apiResponse}>
                        Export
                    </Button>

                    <DownloadExcel
                        invisible={true}
                        data={apiResponse?.data.length > 0 && apiResponse?.data || blankData}

                        buttonLabel="Export Data"
                        fileName={"Report_Policy_Review_" + ((endDate.$M + 1) < 10 ? ("0" + (endDate.$M + 1)) : (endDate.$M + 1)) + "_" + (endDate.$D < 10 ? ("0" + endDate.$D) : endDate.$D) + "_" + endDate.$y}
                        itemKey={"download-csv-button"}
                    />

                </DialogActions>

            </Dialog>
        </div>
    );
}