import styled from "@emotion/styled";
import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import cloneDeep from "lodash/cloneDeep";
import { Colors } from "../theme";
import { Edit, PropaneSharp, SettingsApplications } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import AbcOutlinedIcon from "@mui/icons-material/AbcOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddFieldDialog from "./AddFieldDialog";
import AddDocumentsDrawer from "./AddDocumentsDrawer";
import AddSpecificInfoDialog from "./AddSpecificInfoDialog";
import AddSpecificFieldDialog from "./AddSpecificFieldDialog";
import TagDocuments from "./TagDocuments";
import EditFieldDialog from "./EditFieldDialog";
import EditSpecificFieldDialog from "./EditSpecificFieldDialog";

const TopBar = styled("div")(({ theme }) => ({
  boxShadow: "0px 4px 6px -5px #f0f0f0",
  borderBottom: "1px solid #f0f0f0",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const BottomBar = styled("div")(({ theme }) => ({
  boxShadow: "0px 4px 6px -5px #f0f0f0",
  borderTop: "1px solid #f0f0f0",
  padding: "16px",
  height: "69.5",
  display: "flex",
  alignItems: "center",
}));

const getNextId = () => String(Math.random()).slice(2);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function RightSection(props) {
  console.log(props);
  const commonColumns = [
    {
      field: "infoQuestion",
      headerName: "Name",
      // width: 320,
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AbcOutlinedIcon sx={{ marginRight: "8px" }} />
            {params.value}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InsertDriveFileOutlinedIcon sx={{ marginRight: "8px" }} />
            Name
          </span>
        );
      },
    },
    // { field: "type", headerName: "Type", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px" }}
              onClick={() => {
                console.log(params.row);
                setSelectedField(params.row);
              }}
            >
              <EditOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px" }}
              onClick={(e) => {
                deleteInformation(params.id);
              }}
            >
              <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
          </React.Fragment>
        );
      },
      align: "right",
      headerAlign: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Actions
          </div>
        );
      },
    },
  ];

  const specificColumns = [
    {
      field: "infoQuestion",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AbcOutlinedIcon sx={{ marginRight: "8px" }} />
            {params.value}
          </span>
        );
      },
      renderHeader: (params) => {
        return (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InsertDriveFileOutlinedIcon sx={{ marginRight: "8px" }} />
            Name
          </span>
        );
      },
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton
    //         color="primary"
    //         component="span"
    //         sx={{ width: "36px", height: "36px" }}
    //         onClick={(e) => {
    //           deleteSpecificInformation(params.id);
    //         }}
    //       >
    //         <DeleteOutlineOutlinedIcon color="primary" />
    //       </IconButton>
    //     );
    //   },
    //   align: "right",
    //   headerAlign: "right",
    //   renderHeader: (params) => {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         Actions
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <React.Fragment>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px" }}
              onClick={() => {
                console.log(params.row);
                setSelectedSpecificField(params.row);
              }}
            >
              <EditOutlinedIcon color="primary" />
            </IconButton>
            <IconButton
              color="primary"
              component="span"
              sx={{ width: "36px", height: "36px" }}
              onClick={(e) => {
                deleteSpecificInformation(params.id, params.row.infoType);
              }}
            >
              <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
          </React.Fragment>
        );
      },
      align: "right",
      headerAlign: "right",
      renderHeader: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Actions
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();
  const [openAddFieldDialog, setOpenAddFieldDialog] = React.useState(false);
  const [showAddDocumentsDrawer, setShowAddDocumentsDrawer] =
    React.useState(false);
  const [showAddSpecificInfoDialog, setShowAddSpecificInfoDialog] =
    React.useState(false);
  const [selectedCollectionIndex, setSelectedColletionIndex] =
    React.useState(0);
  const [selectedSpecificInfo, setSelectedSpecificInfo] = React.useState("");
  const [showAddSpecificFieldDialog, setShowAddSpecificFieldDialog] =
    React.useState(false);
  const [selectedField, setSelectedField] = React.useState(null);
  const [selectedSpecificField, setSelectedSpecificField] =
    React.useState(null);

  function addInformation(name, schema) {
    console.log(schema);
    props.setHighlights([
      ...props.highlights,
      {
        id: getNextId(),
        infoType: "common",
        infoQuestion: name,
        // Order: 0,
        schema:
          schema && JSON.stringify(schema) == "[{}]"
            ? null
            : JSON.stringify(schema),
      },
    ]);
    console.log(props.highlights);
  }

  function modifyInformation(id, name, schema) {
    console.log(id, name, schema);
    let tempHighlights = cloneDeep(props.highlights);
    const selectedHighlightIndex = tempHighlights.findIndex(
      (obj) => obj.id == id
    );
    tempHighlights[selectedHighlightIndex].schema =
      schema && JSON.stringify(schema) == "[{}]"
        ? null
        : JSON.stringify(schema);
    tempHighlights[selectedHighlightIndex].infoQuestion = name;
    props.setHighlights(tempHighlights);
  }

  function modifySpecificInformation(id, name, infoType, schema) {
    console.log(id, name, schema);
    let tempSpecificHighlights = cloneDeep(props.specificHighlights);

    let sectionIndex = tempSpecificHighlights.findIndex(
      (element) => element.infoType == infoType
    );
    console.log("SectionIndex", sectionIndex);

    const selectedHighlightIndex = tempSpecificHighlights[
      sectionIndex
    ].fields.findIndex((obj) => obj.id == id);
    console.log("selectedHighlightIndex", selectedHighlightIndex);
    console.log(
      "DATA",
      tempSpecificHighlights[sectionIndex].fields[selectedHighlightIndex]
    );
    tempSpecificHighlights[sectionIndex].fields[selectedHighlightIndex].schema =
      schema && JSON.stringify(schema) == "[{}]"
        ? null
        : JSON.stringify(schema);
    tempSpecificHighlights[sectionIndex].fields[
      selectedHighlightIndex
    ].infoQuestion = name;

    console.log(
      "NEW_DATA",
      tempSpecificHighlights[sectionIndex].fields[selectedHighlightIndex]
    );
    props.setSpecificHighlights(tempSpecificHighlights);
  }

  function addSpecificInformation(name, schema) {
    let newSpecificHighlights = JSON.parse(
      JSON.stringify(props.specificHighlights)
    );

    let sectionIndex = newSpecificHighlights.findIndex(
      (element) => element.infoType == selectedSpecificInfo
    );
    newSpecificHighlights[sectionIndex].fields.push({
      id: getNextId(),
      // infoType: selectedSpecificInfo,
      infoQuestion: name,
      // Order: 0,
      schema:
        schema && JSON.stringify(schema) == "[{}]"
          ? null
          : JSON.stringify(schema),
    });
    props.setSpecificHighlights(newSpecificHighlights);
    console.log(props.specificHighlights);
  }

  function deleteInformation(id) {
    props.setHighlights(props.highlights.filter((item) => item.id !== id));
  }

  function deleteSpecificInformation(id, infoType) {
    let newSpecificHighlights = JSON.parse(
      JSON.stringify(props.specificHighlights)
    );

    console.log(newSpecificHighlights);

    let sectionIndex = newSpecificHighlights.findIndex(
      (element) => element.infoType == infoType
    );
    console.log(sectionIndex);
    console.log(newSpecificHighlights[sectionIndex].field);
    newSpecificHighlights[sectionIndex].fields = newSpecificHighlights[
      sectionIndex
    ].fields.filter((item) => item.id !== id);
    props.setSpecificHighlights(newSpecificHighlights);
  }

  function addSpecificInfoContainer(name) {
    props.setSpecificHighlights([
      ...props.specificHighlights,
      {
        infoType: name,
        fields: [],
      },
    ]);
  }

  function deleteSpecificInfoContainer(name) {
    let newSpecificHighlights = JSON.parse(
      JSON.stringify(props.specificHighlights)
    );

    newSpecificHighlights = newSpecificHighlights.filter(
      (item) => item.infoType !== name
    );
    props.setSpecificHighlights(newSpecificHighlights);
  }

  function addCollection() {
    props.setCollections([
      ...props.collections,
      {
        name: "Collection " + (props.collections.length + 1),
        documents: [],
      },
    ]);
  }

  function deleteCollection(index) {
    console.log(index);
    let newCollections = JSON.parse(JSON.stringify(props.collections));
    newCollections.splice(index, 1);
    props.setCollections(newCollections);
  }

  function addDocsToCollection(files) {
    console.log("Collections before add", props.collections);
    let newCollections = cloneDeep(props.collections);
    console.log("newCollections before add", newCollections);

    for (var i = 0; i < files.length; i++) {
      newCollections[selectedCollectionIndex].documents = [
        ...newCollections[selectedCollectionIndex].documents,
        files[i],
      ];
    }
    console.log(newCollections);
    props.setCollections(newCollections);
  }

  return (
    <div>
      <AddFieldDialog
        openDiag={openAddFieldDialog}
        setOpenDiag={setOpenAddFieldDialog}
        addInformation={addInformation}
      />
      <AddSpecificFieldDialog
        openDiag={showAddSpecificFieldDialog}
        setOpenDiag={setShowAddSpecificFieldDialog}
        addInformation={addSpecificInformation}
      />
      {selectedField && (
        <EditFieldDialog
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          modifyInformation={modifyInformation}
        />
      )}

      {selectedSpecificField && (
        <EditSpecificFieldDialog
          selectedField={selectedSpecificField}
          setSelectedField={setSelectedSpecificField}
          modifyInformation={modifySpecificInformation}
        />
      )}
      <AddDocumentsDrawer
        isOpen={showAddDocumentsDrawer}
        setIsOpen={setShowAddDocumentsDrawer}
        collectionIndex={selectedCollectionIndex}
        collections={props.collections}
        addDocsToCollection={addDocsToCollection}
      />
      <AddSpecificInfoDialog
        openDiag={showAddSpecificInfoDialog}
        setOpenDiag={setShowAddSpecificInfoDialog}
        addSpecificInfoContainer={addSpecificInfoContainer}
      />

      <TopBar>
        {/* <div style={{ display: "flex", alignContent: "center" }}>
          <EditOutlinedIcon
            color="primary"
            sx={{ marginRight: "4px", fontSize: "20px" }}

          ></EditOutlinedIcon>
          <small style={{ color: Colors.inverse }}>
            Form Processing 5/20/2022. 8:19:38 AM
          </small>
        </div> */}
        <Button
          // startIcon={<SaveOutlinedIcon />}
          sx={{
            padding: "4px 8px 4px 8px",
            textTransform: "unset",
            borderRadius: "2px",
          }}
          onClick={() => {
            navigate("/schema_dashboard", {
              replace: false,
            });
          }}
        >
          <span style={{ color: Colors.inverse, marginBottom: "-2px" }}>
            Back
          </span>
        </Button>
        <Divider orientation="vertical" flexItem sx={{ margin: "0px 12px" }} />
        <Button
          startIcon={<SaveOutlinedIcon />}
          sx={{
            padding: "4px 8px 4px 8px",
            textTransform: "unset",
            borderRadius: "2px",
          }}
          onClick={() => {
            props.saveDataToAPI();
          }}
        >
          <span style={{ color: Colors.inverse, marginBottom: "-2px" }}>
            Save and close
          </span>
        </Button>
      </TopBar>
      <div style={{ height: "calc(100vh - 169px)", overflowX: "auto" }}>
        <TabPanel value={props.activeTab} index={0} style={{ padding: "24px" }}>
          <div style={{ maxWidth: "720px", marginBottom: "32px" }}>
            <Typography variant="h5" gutterBottom component="div">
              Choose common information to extract
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              List all pieces of common information that you want the AI model
              to extract from your documents. For example: Name, Address, Total
              amount, Line items... You will tag them in the documents.
            </Typography>
            <Button
              onClick={() => setOpenAddFieldDialog(true)}
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                marginTop: "16px",
                "&:hover": { borderWidth: "2px" },
              }}
            >
              Add
            </Button>
          </div>

          {/* <div style={{ height: 400, width: '100%' }}> */}
          <DataGrid
            sx={{ border: "none" }}
            rows={props.highlights}
            columns={commonColumns}
            disableColumnMenu
            hideFooterPagination={true}
            autoHeight
          />
          {/* </div> */}

          <div style={{ maxWidth: "720px", marginBottom: "32px" }}>
            <Typography variant="h5" gutterBottom component="div">
              Choose specific information to extract
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              List all pieces of specificinformation that you want the AI model
              to extract from your documents. For example: Name, Address, Total
              amount, Line items... You will tag them in the documents.
            </Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                marginTop: "16px",
                "&:hover": { borderWidth: "2px" },
              }}
              onClick={() => {
                setShowAddSpecificInfoDialog(true);
              }}
            >
              Add Specific Info
            </Button>
          </div>
          <div>
            {props.specificHighlights.map((item, index) => (
              <Accordion
                key={index}
                style={{
                  border: "2px solid " + Colors.info,
                  borderRadius: "0px",
                  marginBottom: "8px",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{item.infoType} Section</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{
                        borderRadius: "2px",
                        borderWidth: "2px",
                        padding: "4px 16px",
                        marginBottom: "16px",
                        "&:hover": { borderWidth: "2px" },
                      }}
                      onClick={() => {
                        setSelectedSpecificInfo(item.infoType);
                        setShowAddSpecificFieldDialog(true);
                      }}
                    >
                      Add
                    </Button>
                    <IconButton
                      color="primary"
                      component="span"
                      sx={{ width: "36px", height: "36px" }}
                      onClick={() => {
                        deleteSpecificInfoContainer(item.infoType);
                      }}
                    >
                      <DeleteOutlinedIcon color="primary" />
                    </IconButton>
                  </div>
                  <DataGrid
                    sx={{ border: "none" }}
                    rows={props.specificHighlights[index].fields.map(
                      (rowItem, index) => {
                        return {
                          ...rowItem,
                          infoType: item.infoType,
                        };
                      }
                    )}
                    columns={specificColumns}
                    disableColumnMenu
                    hideFooterPagination={true}
                    autoHeight
                    row
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={props.activeTab} index={1} style={{ padding: "24px" }}>
          <div style={{ maxWidth: "720px", marginBottom: "32px" }}>
            <Typography variant="h5" gutterBottom component="div">
              Add collection of documents
            </Typography>
            <Typography variant="subtitle1" gutterBottom component="div">
              Add sample documents for your model to study. Put similar
              documents into same collection.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              style={{ marginTop: "16px" }}
            >
              Create collection for each layout.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "2px",
                borderWidth: "2px",
                padding: "4px 16px",
                marginTop: "0x",
                "&:hover": { borderWidth: "2px" },
              }}
              onClick={() => addCollection()}
            >
              New Collection
            </Button>
          </div>
          <Typography
            gutterBottom
            component="div"
            style={{ marginTop: "24px" }}
          >
            <strong>Add Documents</strong>
          </Typography>
          <div
            style={{
              marginTop: "16px",
              display: "grid",
              gridTemplateColumns: "repeat( auto-fit, minmax(260px, 260px) )",
              gap: "24px",
            }}
          >
            {props.collections.map((item, index) => (
              <Card
                sx={{ width: "260px", padding: "8px 16px 16px 16px" }}
                elevation={2}
                key={index}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "8px",
                  }}
                >
                  <IconButton
                    onClick={() => deleteCollection(index)}
                    color="primary"
                    component="span"
                    sx={{ width: "36px", height: "36px" }}
                  >
                    <DeleteOutlinedIcon color="primary" />
                  </IconButton>
                </div>
                <CardActionArea
                  style={{
                    height: "140px",
                    display: "grid",
                    placeItems: "center",
                    background: Colors.body_bg,
                  }}
                  onClick={() => {
                    setSelectedColletionIndex(index);
                    setShowAddDocumentsDrawer(true);
                  }}
                >
                  <AddCircleOutlineOutlinedIcon
                    color="inverse"
                    style={{ fontSize: "48px" }}
                  />
                </CardActionArea>
                <CardActions style={{ display: "block" }}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    component="div"
                    style={{ marginTop: "8px" }}
                  >
                    <strong>{item.name}</strong>
                  </Typography>
                  <div style={{ color: Colors.inverse, fontSize: "0.9rem" }}>
                    {item.documents.length} document(s)
                  </div>
                </CardActions>
              </Card>
            ))}
          </div>
        </TabPanel>
        <TabPanel value={props.activeTab} index={2}>
          <TagDocuments
            highlights={props.highlights}
            setHighlights={props.setHighlights}
            documents={
              props.collections[selectedCollectionIndex] &&
              props.collections[selectedCollectionIndex].documents
            }
            specificHighlights={props.specificHighlights}
            setSpecificHighlights={props.setSpecificHighlights}
          />
        </TabPanel>
      </div>
      {/* <BottomBar>
        <Button
          variant="contained"
          color="inverse"
          disableElevation
          onClick={(e) => props.setActiveTab(props.activeTab - 1)}
          style={{ marginRight: "16px" }}
          disabled={props.activeTab < 1}
        >
          Back
        </Button>

        <Button
          variant="contained"
          disableElevation
          onClick={(e) => props.setActiveTab(props.activeTab + 1)}
          disabled={props.activeTab > 2}
        >
          Next
        </Button>
      </BottomBar> */}
    </div>
  );
}

// function RightSection() {

//     return (
//         <div>
//
//         </div>
//     )
// }

export default RightSection;
