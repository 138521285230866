import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import getRelativeTime from "../../utils/getRelativeTime";
import { PropaneSharp } from "@mui/icons-material";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import AddTransactionCommentAPI from "../../api/AddTransactionComment";
import AddTransactionLevelCommentsAPI from "../../api/AddTransactionLevelComments";

const CommentRow = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "16px",
}));

export default function CommentDialog(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [newComment, setNewComment] = React.useState("");
  const [commentsData, setCommentsData] = React.useState([]);
  const [isCommentAdded, setIsCommentAdded] = React.useState(false);
  React.useEffect(() => {
    props.data.remarks && setCommentsData(props.data.remarks);
  }, []);

  function uploadComment() {
    setIsCommentAdded(true);
    setIsLoading(true);
    AddTransactionLevelCommentsAPI({
      remark: newComment,
      transactionId: props.data.transactionId,
    })
      .then((response) => {
        console.log(response);
        setCommentsData(response.data);
        setNewComment("");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <div>
      <Dialog
        open={props.data !== null}
        onClose={() => {
          isCommentAdded && props.setForceRefresh(Math.random());
          props.setData(null);
        }}
      >
        <DialogTitle id="alert-dialog-title">Comments</DialogTitle>
        <Divider />
        <DialogContent style={{ minWidth: "480px", padding: "16px" }}>
          {commentsData.length > 0 ? (
            commentsData.map((remark, remarkIndex) => (
              <React.Fragment key={remarkIndex}>
                <CommentRow>
                  <div>
                    <Avatar>{remark.user[0]}</Avatar>
                  </div>
                  <div>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 600, fontSize: "1rem" }}
                      >
                        {remark.user}
                      </Typography>
                      <small>
                        {getRelativeTime(
                          new Date(),
                          new Date(remark.timestamp)
                        )}
                      </small>
                    </div>
                    <Typography variant="body2">{remark.value}</Typography>
                  </div>
                </CommentRow>
                {remarkIndex < commentsData.length - 1 && (
                  <Divider style={{ marginBottom: "16px", marginTop: "8px" }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2">
              Looks like there are no comments for this transaction.
            </Typography>
          )}
        </DialogContent>
        <Divider />
        <DialogActions style={{ display: "block" }}>
          <div>
            <TextField
              InputProps={{
                style: { padding: "8px 12px", fontSize: "0.9rem" },
              }}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              multiline
              rows={2}
              fullWidth
            />
          </div>

          <div
            style={{
              marginTop: "8px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={(e) => {
                isCommentAdded && props.setForceRefresh(Math.random());
                props.setData(null);
              }}
              variant="contained"
              disableElevation
              size="small"
              color="secondary"
            >
              Close
            </Button>
            <Button
              variant="contained"
              disableElevation
              type="submit"
              size={"small"}
              style={{ marginLeft: "16px" }}
              onClick={(e) => {
                uploadComment();
              }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <span style={{ display: "flex", gap: "8px" }}>
                  <AddCommentOutlinedIcon
                    style={{ fontSize: "20px", marginTop: "1px" }}
                  />
                  <div
                    style={{
                      width: "2px",
                      height: "20px",
                      background: "white",
                    }}
                  ></div>
                  Add Comment
                </span>
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
