import styled from "@emotion/styled";
import { Box, Collapse, List } from "@mui/material";
import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Divider from "@mui/material/Divider";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";

const Root = styled("div")(({ theme }) => ({
  width: "240px",
  boxShadow: "0px 0px 6px -2px #f0f0f0",
  borderRight: "1px solid #f0f0f0",
  minHeight: "calc(100vh - 52px)",
  // padding: '16px'
}));

const currentRoute = window.location.pathname;

function Sidebar() {
  let navigate = useNavigate();
  const [expandManageUsers, setExpandManageUsers] = React.useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("/admin_panel/")) {
      setExpandManageUsers(true);
    }
  }, []);

  return (
    <Root>
      <List>
        <ListItem
          disablePadding
          selected={window.location.pathname == "/dashboard"}
          onClick={() =>
            navigate(
              "/dashboard",
              { replace: true }
            )
          }
        >
          <ListItemButton>
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          selected={window.location.pathname == "/schema_dashboard"}
          onClick={() => navigate("/schema_dashboard", { replace: true })}
        >
          <ListItemButton>
            <ListItemIcon>
              <GradingOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Models"} />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItemButton
          onClick={() => setExpandManageUsers(!expandManageUsers)}
        >
          <ListItemIcon>
            <AdminPanelSettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Administration" />
          {expandManageUsers ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandManageUsers} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                navigate("../admin_panel/manage_users", { replace: true })
              }
              selected={window.location.pathname == "/admin_panel/manage_users"}
            >
              <ListItemIcon>
                <AccountCircleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Users" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() =>
                navigate("../admin_panel/manage_roles", { replace: true })
              }
              selected={window.location.pathname == "/admin_panel/manage_roles"}
            >
              <ListItemIcon>
                <VerifiedUserOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Roles" />
            </ListItemButton>
          </List>
        </Collapse>
        {/* <ListItemButton onClick={() => setExpandModels(!expandModels)}>
          <ListItemIcon>
            <ModelTrainingOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Models" />
          {expandModels ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandModels} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/schema_builder", { replace: true })}
            >
              <ListItemIcon>
                <GradingOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Create Model" />
            </ListItemButton>
          </List>
        </Collapse> */}
      </List>
    </Root>
  );
}

export default Sidebar;
