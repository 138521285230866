import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { Colors } from '../../theme'
import { FunctionsRounded } from '@mui/icons-material';
import DeleteUserAPI from '../../../api/DeleteUser';



function DeleteUserDialog(props) {
    const [isLoading, setIsLoading] = React.useState(false)

    function DeleteUser() {
        setIsLoading(true)
        DeleteUserAPI({
            id: props.user.id
        }).then(response => {
            setIsLoading(false)
            if (response.data.successResponse) {
                window.location.reload()

            }
            else {
                alert(response.data.error)
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <Dialog open={props.openDiag} onClose={() => props.setOpenDiag(false)}>
            <DialogTitle>Delete User</DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: '0px 24px 24px 24px' }}>
                <div style={{ width: '360px', textAlign: 'center', padding: '16px 24px 0px 24px' }}>
                    <ReportGmailerrorredOutlinedIcon style={{ fontSize: '50px' }} color="warning" />
                    <Typography variant='h6' style={{ color: Colors.warning }} gutterBottom>Caution!</Typography>
                    <Typography variant='body2'>Are you sure you want to delete this user?<br />This action is irreversible.</Typography>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={() => props.setOpenDiag(false)} color="inverse">Cancel</Button>
                <Button onClick={() => DeleteUser()}>                        {isLoading ? <CircularProgress color="inherit" size={23} /> : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteUserDialog